/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React from "react";
import { Card, Col } from "react-bootstrap";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { useSelector } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";
import { capitalize } from "utils/strings";
import useIsDeepTech from "hooks/useDeeptech";

const MyProviderCard = () => {
  const { profileInfo } = useSelector(profileSelector);
  const isDeepTech = useIsDeepTech();
  const _profile = isDeepTech
    ? profileInfo?.data?.profile?.deep_tech_profile
    : profileInfo?.data?.profile ?? null;

  const CardBody = ({ profile }) => {
    const provider = isDeepTech ? profile?.provider : profile?.fellow_provider;

    return (
      <Card.Body>
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <h4 className="mb-0">
              {profile?.cohort?.name} {profile?.cohort?.rank}
            </h4>
          </div>
        </div>

        <div className="ms-2 d-flex flex-column">
          <h4>{provider?.name}</h4>
          <h6>
            Applied Learning Cluster ID:
            {provider?.provider_id}
          </h6>
          <h6>Phone Number:{provider?.phone_number ?? "NULL"}</h6>
          <h6>
            Email:{" "}
            {capitalize(isDeepTech ? provider?.poc_email : provider?.email)}
          </h6>
          <h6>Address: {capitalize(provider?.address ?? "Nill")}</h6>
          <h6>
            ALC Whatsapp group:{" "}
            {provider?.whatsapp_group_link ? (
              <a href={provider?.whatsapp_group_link} className="text-primary">
                Join here
              </a>
            ) : (
              <span>Not available</span>
            )}
          </h6>
          {provider?.map_location && (
            <a href={provider?.map_location} target="blank">
              <button type="button" className="btn btn-primary">
                Locate your ALC
              </button>
            </a>
          )}
        </div>
      </Card.Body>
    );
  };

  return (
    <Col xl="6">
      <Card>
        <Card.Header className="d-block">
          <Card.Title>My Applied Learning Cluster</Card.Title>
          <Card.Text className="m-0 subtitle"></Card.Text>
        </Card.Header>
        {(
          isDeepTech
            ? _profile?.provider?.name
            : _profile?.fellow_provider?.name
        ) ? (
          <CardBody profile={_profile} />
        ) : (
          <Card.Body>
            <div className="d-flex justify-content-center">
              <BagIcon />
            </div>
            <div className="d-flex justify-content-center">
              <p>You have no training provider assigned yet</p>
            </div>
          </Card.Body>
        )}
      </Card>
    </Col>
  );
};

export default MyProviderCard;

import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Nigerian_States, LocalGov } from "utils/constant.data";
import { selectStyles } from "../styles/customStyles";
import { ReactComponent as ResetIcon } from "images/svg/reset.svg";
import { toast } from "react-toastify";
import { useGetAllCoursesPublicQuery } from "store/services/adminAPI";

const LeaderboardFilterModal = ({
  isVisible,
  setFilter,
  setFilterString,
  setIsFilterModalVisible,
  setActiveRecord
}) => {
  const [selectedStates, setStates] = useState([]);
  const [selectedLGAs, setLGAs] = useState([]);
  const [selectedGender, setGender] = useState(null);
  const [selectedCourse, setCourse] = useState([]);
  const [minScore, setMinScore] = useState("");
  const [maxScore, setMaxScore] = useState("");
  const modalRef = useRef(null);
  
  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1001,
  };

  const { data: courses } = useGetAllCoursesPublicQuery("program=3mtt");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current && 
        !modalRef.current.contains(event.target) && 
        !event.target.closest('.react-select__container') && 
        !event.target.closest('.react-select__menu')
      ) {
        setIsFilterModalVisible(false);
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, setIsFilterModalVisible]);

  const getLGData = () => {
    const LGs = [];
    selectedStates.forEach((state) => {
      LocalGov?.forEach((val) => {
        if (val?.state?.toLowerCase() === state.value?.toLowerCase()) {
          LGs.push(...val?.lgas);
        }
      });
    });

    return LGs.length > 0
      ? LGs.map((state) => ({
          value: state,
          label: state,
        }))
      : [];
  };

  const StateOptions = Nigerian_States.map((state) => ({
    value: state,
    label: state,
  }));

  const CourseOptions =
    courses?.data?.courses?.length > 0
      ? courses?.data?.courses?.map((course) => ({
          value: course?.id,
          label: course?.title,
        }))
      : [];

  const GenderOptions = [
    { label: "All", value: "" },
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  const handleScoreChange = (e, type) => {
    const value = e.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      if (type === "min") {
        setMinScore(value);
      } else {
        setMaxScore(value);
      }
    }
  };

  const handleReset = () => {
    setStates([]);
    setLGAs([]);
    setGender(null);
    setCourse([]);
    setMinScore("");
    setMaxScore("");
    setFilter(null);
    setFilterString("");
    setIsFilterModalVisible(false);
  };

  const handleApplyFilter = () => {
    if (
      selectedStates.length === 0 &&
      selectedLGAs.length === 0 &&
      !selectedGender &&
      selectedCourse.length === 0 &&
      minScore === "" &&
      maxScore === ""
    ) {
      toast.warn("✔️ Please Select a Filter !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      setFilter({
        state: selectedStates.length > 0 ? selectedStates : [],
        lga: selectedLGAs.length > 0 ? selectedLGAs : [],
        gender: selectedGender?.value || "",
        course: selectedCourse.length > 0 ? selectedCourse : [],
        min_score: minScore !== "" ? minScore/10 : "",
        max_score: maxScore !== "" ? maxScore/10 : "",
      });
      if (setActiveRecord) {
        setActiveRecord(null);
      }
      setIsFilterModalVisible(false);
    }
  };

  const isFilterSelected = () => {
    return (
      selectedStates.length === 0 &&
      selectedLGAs.length === 0 &&
      !selectedGender &&
      selectedCourse.length === 0 &&
      minScore === "" &&
      maxScore === ""
    );
  };

  const CustomSelect = (props) => (
    <div 
      className="react-select__container" 
      onClick={(e) => e.stopPropagation()}
    >
      <Select 
        {...props} 
        styles={{
          ...selectStyles,
          container: (provided) => ({
            ...provided,
            width: '100%'
          })
        }}
        components={{
          IndicatorSeparator: () => null,
          ...props.components
        }}
      />
    </div>
  );

  if (!isVisible) return null;

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000, 
        }}
      />
      <div className="filter-modal" style={modalStyle} ref={modalRef}>
        <div className="filter-modal-content">
          <h6>Filter</h6>
          <div className="filter-section">
            <div className="filter-group">
              <label>State</label>
              <CustomSelect
                value={selectedStates}
                onChange={setStates}
                options={StateOptions}
                isMulti
                placeholder="Filter by State"
              />
            </div>
            <div className="filter-group">
              <label>LGA</label>
              <CustomSelect
                value={selectedLGAs}
                onChange={setLGAs}
                options={getLGData()}
                isMulti
                placeholder="Filter by LGA"
              />
            </div>
          </div>
          <div className="filter-section">
            <div className="filter-group">
              <label>Gender</label>
              <CustomSelect
                value={selectedGender}
                onChange={setGender}
                options={GenderOptions}
                isClearable
                placeholder="Filter by Gender"
              />
            </div>
            <div className="filter-group">
              <label>Course</label>
              <CustomSelect
                value={selectedCourse}
                onChange={setCourse}
                options={CourseOptions}
                isMulti
                placeholder="Filter by Course"
              />
            </div>
          </div>
          <div className="filter-section">
            <div className="filter-group">
              <label>Darey Score Range</label>
              <div className="score-range-container">
                <input
                  type="text"
                  value={minScore}
                  onChange={(e) => handleScoreChange(e, "min")}
                  placeholder="Min Score"
                />
                <input
                  type="text"
                  value={maxScore}
                  onChange={(e) => handleScoreChange(e, "max")}
                  placeholder="Max Score"
                />
              </div>
            </div>
          </div>
          <div className="filter-section">
            <div className="filter-group">
              <div className="score-range-container">
                <button className="reset-filter" onClick={()=>handleReset()}>
                  <ResetIcon />
                  Reset Filter
                </button>
                <button
                  className="show-results-button"
                  disabled={isFilterSelected()}
                  onClick={handleApplyFilter}
                >
                  Show Result
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaderboardFilterModal;
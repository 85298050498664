/** @format */

import React from "react";

import Check from "images/vector/check.png";
import Bookmarks from "images/vector/bookmarks.png";
import Bulb from "images/vector/bulb.png";
import { useSelector } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";
import { ReactComponent as BookIcon } from "images/svg/bookicon.svg";
import { ReactComponent as RightIcon } from "images/svg/righticon.svg";
import { shortenWord, validStatuses } from "utils/strings";
import { useGetFellowCohortStatsQuery } from "store/services/adminAPI";
import useIsDeepTech from "hooks/useDeeptech";

export default function CourseBlog() {
  const { profileInfo } = useSelector(profileSelector);
  const _profile = profileInfo?.data?.profile ?? null;
  const isDeepTech = useIsDeepTech()


  const { data: cohort_stats } = useGetFellowCohortStatsQuery(undefined, {
    skip: _profile?.current_program === "deeptech" ? !_profile?.deep_tech_profile?.cohort :!_profile?.cohort
  })
  
  const rank = _profile?.current_program === "deeptech" ? profileInfo?.data?.profile?.deep_tech_profile.cohort?.rank :  profileInfo?.data?.profile?.cohort?.rank ?? 0;
  const deeptechProfile = _profile?.deep_tech_profile ?? null;

  const CourseBlogData = [
    {
      coloumClass: "col-sm-6",
      classChange: "bg-secondary",
      image: Check,
      imgClass: "",
      number:
        validStatuses.includes(_profile?.status?.toLowerCase())
          ? _profile?.current_program === "deeptech"? "Deeptech ID":  "Fellow ID" 
          : "Community ID",
      title: _profile?.fellow_profile_id,
      svgicon: <RightIcon />,
      show: true,
    },
    {
      coloumClass: "col-sm-6",
      title: isDeepTech? 'Course': "Approved assessments",
      image: Bookmarks,
      imgClass: "bookmarks",
      number: isDeepTech ?  _profile?.deep_tech_profile?.course ?   shortenWord(_profile?.deep_tech_profile?.course?.title) : "Null"  :   cohort_stats?.data?.approved_assessments_count ?? 0,
      svgicon: <BookIcon />,
      show: isDeepTech  ? deeptechProfile?.course ?  true :false : validStatuses.includes(_profile?.status?.toLowerCase()) ? true : false,
    },
    {
      coloumClass: "col-sm-12",
      classChange: "bg-danger",
      title: "Attendance Progress",
      image: Bulb,
      imgClass: "bulb",
      number:  cohort_stats ? `${cohort_stats?.data?.attendance_percentage}%` :  "0%",
      svgicon: <BookIcon />,
      show: validStatuses.includes(_profile?.status?.toLowerCase()) ? true : false,
    },
  ];

  return (
    <>
      <div className="row">
        {CourseBlogData.filter((_item) => _item.show).map((item, ind) => (
          <div className={`col-xl-4 ${item.coloumClass}`} key={ind}>
            <div className={`dlab-cource ${item.classChange}`}>
              <div className="d-flex align-items-center">
                <span className="course-icon">{item.svgicon}</span>
                <div className="d-flex flex-column">
                  <div className="ms-2">
                    <h4 className="mb-0">{item.number}</h4>
                    <span>{item.title}</span>
                  </div>
                  {
                    ind === 0 && rank > 0 && <div className="ms-2">
                      <h6 className="mb-0 text-white">Cohort {rank}</h6>
                    </div>
                  }

                </div>

              </div>
              <img src={item.image} alt="" className={item.imgClass} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

/** @format */
import * as Yup from "yup";

export const replaceUnderscoresWithSpaces = (inputString) => {
  return inputString ? inputString.replace(/_/g, " ") : "";
};

// export const capitalize = (value) => {
//   if (typeof value !== "string") return "";
//   const words = value?.toLocaleLowerCase().trim().split(" ");
//   return words
//     .map((word) =>
// word?.length === 0 ? word : word[0].toUpperCase() + word.substring(1)
//     )
//     .join(" ");
// };

export const capitalize = (sentence) => {
  // if (typeof value !== "string") return "";

  if (!sentence || sentence.length === 0) {
    return "";
  }
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export const capitalizeFirstWord = (sentence) => {
  // if (typeof value !== "string") return "";

  if (!sentence || sentence.length === 0) {
    return "";
  }
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export const capitalizeSentences = (text) => {
  // Split the text into an array of sentences using a regular expression
  const sentences = text.split(/(?<=[.!?])\s+/);

  // Capitalize the first letter of each sentence
  const capitalizedSentences = sentences.map((sentence) => {
    // Trim the sentence to remove leading spaces
    sentence = sentence.trim();

    // Capitalize the first letter of the sentence
    if (sentence.length > 0) {
      sentence = sentence.charAt(0).toUpperCase() + sentence.slice(1);
    }

    return sentence;
  });

  // Join the sentences back together
  return capitalizedSentences.join(" ");
};

export const formatUnderScore = (value) => {
  let data = value?.toString();
  let newvalue = data?.replaceAll("_", " ");
  return value ? capitalize(newvalue) : "";
};

export const removeSpaces = (inputString) => {
  const wordsArray = inputString.split(" ");
  const stringWithoutSpaces = wordsArray.join("");
  return stringWithoutSpaces;
};

export const formatHyphen = (value) => {
  let data = value?.toString();
  let newvalue = data?.replaceAll("-", " ");
  return value ? capitalize(newvalue) : "";
};

export const replaceSpace = (value) => {
  let data = value?.toString();
  let newvalue = data?.replaceAll(" ", "_");
  return value ? capitalize(newvalue) : "";
};

export const mapStatusClass = (option) => {
  let res = "";
  let _option = option.toLowerCase();

  switch (_option) {
    case "new":
    case "application_incomplete":
      res = "new";
      break;
    case "selected":
      res = "selected";
      break;
    case "shortlisted":
      res = "shortlisted";
      break;
    case "admitted":
      res = "admitted";
      break;
    case "application completed":
      res = "employed";
      break;
    case "enrolled":
      res = "enrolled";
      break;
    case "in_training":
      res = "training";
      break;
    case "certified":
      res = "certified";
      break;
    case "employed":
      res = "employed";
      break;
    case "incomplete_alc_training":
      res = "incomplete_alc_training";
    break;
    case "completed_alc_training":
      res = "completed_alc_training";

    break;
    case "inactive":
      res = "inactive";
    break;
    case "placed":
    case "deployed":
      res = "employed";
      break;
    case "deeptech":
      res = "deeptech"
      break;
    default:
      res = "";
      break;
  }

  return res;
};

export const mapStatusHeading = (option) => {
  let res = "";
  let _option = option.toLowerCase();

  switch (_option) {
    case "new":
    case "application_incomplete":
      res = "Application Incomplete";
      break;
    case "lc_member":
      res = "LC Member";
      break;
    case "selected":
      res = "Selected";
      break;
    case "blacklisted":
      res = "Blacklisted";
      break;
    case "shortlisted":
      res = "Shortlisted";
      break;
    case "waitlisted":
      res = "Waitlisted";
      break;
    case "admitted":
      res = "Admitted";
      break;
    case "application_completed":
      res = "Application Completed";
      break;
    case "application_approved":
      res = "Application Approved";
      break;
    case "enrolled":
      res = "Enrolled";
      break;
    case "in_training":
      res = "Training";
      break;
    case "certified":
      res = "Certified";
      break;

    case "employed":
      res = "Employed";
      break;
    case "incomplete_alc_training":
      res = "Incomplete ALC Training";
    break;
    case "completed_alc_training":
      res = "Completed ALC Training";
    break;

    case "inactive":
      res = "Inactive";
    break;
    case "placed":
    case "deployed":
      res = "Employed";
      break;
    case "deeptech":
      res = "Deeptech"
      break;
    default:
      res = "";
      break;
  }

  return res;
};

export const mapStatus = (option) => {
  let res = "";
  let _option = option.toLowerCase();

  switch (_option) {
    case "new":
    case "application_incomplete":
      res = "btn-light";
      break;
    case "application_completed":
      res = "btn-warning";
      break;
    case "application_approved":
      res = "btn-poly-green";
      break;
    case "lc_member":
      res = "btn-primary";
      break;
    case "blacklisted":
      res = "btn-danger";
      break;
    case "shortlisted":
      res = "btn-primary";
      break;
    case "admitted":
      res = "Admitted";
      break;
    case "selected":
      res = "btn-poly-green";
      break;
    case "in_training":
      res = "btn-light";
      break;
    case "certified":
      res = "btn-poly-green";
      break;
    case "inactive":
      res = "btn-danger";
      break;
    case "incomplete_alc_training":
      res = "btn-warning";
      break;
    case "completed_alc_training":
      res = "btn-light";
      break;
    case "employed":
      res = "btn-poly-green";
      break
    case "placed":
      res = "btn-poly-green";
      break;
    case "deployed":
      res = "btn-poly-green";
      break;
    case "deeptech":
      res = "deeptech"
      break;
    default:
      res = "";
      break;
  }

  return res;
};

export const mapStatusInformation = (option) => {
  let res = "";
  let _option = option.toLowerCase();

  switch (_option) {
    case "new":
    case "application_incomplete":
      res = "Your application will not be reviewed until completed.";
      break;
    case "blacklisted":
      res =
        "Based on your request, you have now been blacklisted for this phase of the programme";
      break;
    case "lc_member":
      res = "You are a member of our learning commnuity";
      break;
    case "selected":
      res =
        "Congratulations, you have been selected as a training provider for this phase.";
      break;
    case "waitlisted":
      res =
        "You are now on the training provider waitllist. You will be notified in the next phase of the training.";
      break;
    case "rejected":
      res =
        "You have not been selected as a training provider. You can re-apply in the next phase.";
      break;
    case "shortlisted":
      res = "Shortlisted";
      break;
    case "admitted":
      res = "Admitted";
      break;
    case "application_completed":
      res = "Your application is complete and under review.";
      break;
    case "application_approved":
      res =
        "Your application has now been approved and is awaiting verification";
      break;
    case "enrolled":
      res = "Enrolled";
      break;
    case "in_training":
      res = "Training";
      break;
    case "certified":
      res = "Certified";
      break;
    case "deactivated":
      res = "Deactivated";
      break;
    default:
      res = "";
      break;
  }

  return res;
};

export const mapFellowStatusInformation = (option) => {
  let res = "";
  let _option = option.toLowerCase();

  switch (_option) {
    case "new":
    case "application_incomplete":
      res = "Your application will not be reviewed until completed.";
      break;
    case "lc_member":
      res = "You are a member of our learning commnuity";
      break;
    case "selected":
      res =
        "You have been selected as a fellow for this phase of the programme";
      break;
    case "waitlisted":
      res =
        "You are now on the training provider waitllist. You will be notified in the next phase of the training.";
      break;
    case "rejected":
      res =
        "You have not been selected as a training provider. You can re-apply in the next phase.";
      break;
    case "shortlisted":
      res = "Shortlisted";
      break;
    case "admitted":
      res = "Admitted";
      break;
    case "application_completed":
      res = "Your application is complete and under review.";
      break;
    case "application_approved":
      res =
        "Your application has now been approved and is awaiting verification";
      break;
    case "enrolled":
      res = "Enrolled";
      break;
    case "in_training":
      res = "Training";
      break;
    case "inactive":
      res = "You have not been an active fellow with your Applied Learning Cluster (ALC)"
      break
    case "incomplete_alc_training":
      res = "You have not completed your training requirements with your Applied Learning Cluster (ALC)"
      break
    case "completed_alc_training":
      res = "You have completed your training requirements with your Applied Learning Cluster (ALC)"
      break
    case "employed":
      res = "You are now employed with a 3MTT Placement Organisation"
      break
    case "placed":
    case "deployed":
      res = "You are now employed with a 3MTT Placement Organisation";
      break;
    case "certified":
      res = "You have completed all training requirements to be a certified 3MTT fellow";
      break;
    case "deactivated":
      res = "Deactivated";
      break;
    case "deeptech":
      res = "You have been advanced to Deeptech"
      break;
    default:
      res = "";
      break;
  }

  return res;
};

export const Colors = {
  polyGreen: "#29562a",
  pigmentGreen: "#26a65b",
  yellowGreen: "#b2d235",
  peachYellow: "#F9DCAD",
  lemon: "#0cf2df",
  aliceBlue: "#dff4fe",
  darkGray: "#2c3e4b",
  gray3: "#808b93",
  gray2: "#d4d8db",
  gray1: "#f8f9fa",
};

export const AgeOptions = [
  { value: "", label: "Select Age Range" },
  { value: "< 18", label: "Below 18" },
  { value: "18-25", label: "18 - 25 years" },
  { value: "26-35", label: "26 - 35 years" },
  { value: "36-45", label: "36 - 45 years" },
  { value: "> 45", label: "Above 45" },
];

export const EmploymentOptions = [
  { value: "", label: "Select Employment Status" },
  { value: "full_time", label: "Employed (Full Time)" },
  { value: "part_time", label: "Employed (Part Time)" },
  { value: "self_employed", label: "Self Employed (Business Owner)" },
  { value: "student", label: "Student" },
  { value: "unemployed", label: "Unemployed" },
];

export const EmploymentSurveyOptions = [
  { value: "", label: "Select Employment Type" },
  { value: "full-time", label: "Full Time" },
  { value: "Internship", label: "Internship" },
  { value: "Self-employed", label: "Self Employed (Business Owner)" },
  { value: "Micro Job", label: "Micro Job" },
]

export const ChannelOfCurrentEmploymentOptions = [
  { value: "", label: "Select Channel of Current Employment" },
  { value: "3MTT’s placement & Partnerships", label: "3MTT’s placement & Partnerships" },
  { value: "Personal networking", label: "Personal networking" },
  { value: "Online job portals", label: "Online job portals" },
  { value: "Others", label: "Others" },
];

export const PlacementPartnerOptions = [
  { value: "", label: "Select Placement Partner" },
  { value: "3MTT Job board", label: "3MTT Job Board" },
  { value: "NJSP", label: "NJSP" },
  { value: "USPF", label: "USPF" },
  { value: "Awarri", label: "Awarri" },
];


export const UnsuccessfulJobAttemptsOptions = [
  { value: "", label: "Select Reason" },
  { value: "lack-of-skills", label: "Lack of relevant skills" },
  { value: "insufficient-experience", label: "Insufficient work experience" },
  { value: "poor-interview", label: "Poor interview performance" },
  { value: "high-competition", label: "High competition for roles" },
  { value: "economic-factors", label: "Economic factors" },
  { value: "other", label: "Other – please specify" },
];

export const DisabilityOptions = [
  { value: "", label: "Select Disability" },
  { value: "visual-impairment", label: "Visual impairment" },
  { value: "hearing-impairment", label: "Hearing impairment" },
  { value: "physical-impairment", label: "Physical impairment" },
  { value: "intellectual-impairment", label: "Intellectual impairment" },
  { value: "communication-impairment", label: "Communication impairment" },
  { value: "other", label: "Other disability" },
];

export const ScaleOptions = [
  { value: "", label: "Select a number" },
  ...Array.from({ length: 10 }, (_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  })),
];


export const courseOptions = [
  { value: "", label: "Select Course" },
  { value: "AI/ML", label: "AI/ML" },
  { value: "Data Science", label: "Data Science" },
  { value: "Software Development", label: "Software Development" },
  { value: "Animation", label: "Animation" },
  { value: "Cloud Computing", label: "Cloud Computing" },
  { value: "DevOps", label: "DevOps" },
  { value: "UI/UX", label: "UI/UX" },
  { value: "Cyber Security", label: "Cyber Security" },
  { value: "Game Development", label: "Game Development" },
  {
    value: "Data_Analysis_Visualization",
    label: "Data Analysis & Visualization",
  },
  { value: "Product Management", label: "Product Management" },
  { value: "Quality Assurance", label: "Quality Assurance" },
];

export const EnglishProficiencyLevelOptions = [
  { value: "", label: "Select English Proficiency Level" },
  { value: "beginner", label: "Beginner" },
  { value: "pre_intermediate", label: "Pre-Intermediate" },
  { value: "intermediate", label: "Intermediate" },
  { value: "upper_intermediate", label: "Upper Intermediate" },
  { value: "advanced", label: "Advanced" },
  { value: "proficient", label: "Proficient" },
];

export const GraduationClassOptions = [
  { value: "", label: "Select Graduation Class" },
  { value: "first", label: "First Class" },
  { value: "second", label: "Second Class" },
  { value: "third", label: "Third Class" },
  { value: "pass", label: "Pass" },
];

export const ProviderOptions = [
  { value: "", label: "Select Status" },
  { value: "New", label: "New" },
  { value: "Selected", label: "Selected" },
  { value: "application_incomplete", label: "Application Incomplete" },
  { value: "application_completed", label: "Application Completed" },
  { value: "application_approved", label: "Application Approved" },
  { value: "Blacklisted", label: "Blacklisted" },
];

export const FellowOptions = [
  { value: "", label: "Select Status" },
  { value: "New", label: "New" },
  { value: "Selected", label: "Selected" },
  { value: "application_completed", label: "Application Completed" },
];


export const FellowProficiencyLevel = [
  { value: "", label: "Select Level" },
  {value: "Novice", label: "Novice"},
  {value: "Beginner", label: "Beginner"},
  {value: "Intermediate", label: "Intermediate"},
  {value: "Advanced", label: "Advanced"}
]

export const FellowProficiencyRating = [
  { value: "", label: "Select Status" },
  {value: 1, label: "1"},
  {value: 2, label: "2"},
  {value: 3, label: "3"},
  {value: 4, label: "4"},
  {value: 5, label: "5"},
  
]


export const UpdateFellowStatusOptions = [
  { value: "", label: "Select Status" },
  {value:"Inactive", label:"Inactive"},
  {value:"incomplete_alc_training", label:"⁠Incomplete ALC Training"},
  {value:"completed_alc_training", label:"⁠Completed ALC Training"},
  {value:"Employed", label:"Employed"}
]

export const stateOptions = [
  { value: "", label: "Select State" },

  { value: "Abia", label: "Abia" },
  { value: "Federal Capital Territory", label: "Abuja" },
  { value: "Adamawa", label: "Adamawa" },
  { value: "Akwa Ibom", label: "Akwa Ibom" },
  { value: "Anambra", label: "Anambra" },
  { value: "Bauchi", label: "Bauchi" },
  { value: "Bayelsa", label: "Bayelsa" },
  { value: "Benue", label: "Benue" },
  { value: "Borno", label: "Borno" },
  { value: "Cross River", label: "Cross River" },
  { value: "Delta", label: "Delta" },
  { value: "Ebonyi", label: "Ebonyi" },
  { value: "Edo", label: "Edo" },
  { value: "Ekiti", label: "Ekiti" },
  { value: "Enugu", label: "Enugu" },
  { value: "Gombe", label: "Gombe" },
  { value: "Imo", label: "Imo" },
  { value: "Jigawa", label: "Jigawa" },
  { value: "Kaduna", label: "Kaduna" },
  { value: "Kano", label: "Kano" },
  { value: "Katsina", label: "Katsina" },
  { value: "Kebbi", label: "Kebbi" },
  { value: "Kogi", label: "Kogi" },
  { value: "Kwara", label: "Kwara" },
  { value: "Lagos", label: "Lagos" },
  { value: "Nasarawa", label: "Nasarawa" },
  { value: "Niger", label: "Niger" },
  { value: "Ogun", label: "Ogun" },
  { value: "Ondo", label: "Ondo" },
  { value: "Osun", label: "Osun" },
  { value: "Oyo", label: "Oyo" },
  { value: "Plateau", label: "Plateau" },
  { value: "Rivers", label: "Rivers" },
  { value: "Sokoto", label: "Sokoto" },
  { value: "Taraba", label: "Taraba" },
  { value: "Yobe", label: "Yobe" },
  { value: "Zamfara", label: "Zamfara" },
];

export const GenderOptions = [
  { value: "", label: "Select Gender" },
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];
export const SkillOptions = [
  { value: "", label: "Select Skill Level" },
  {
    value: "Beginner level (Zero 0 or less than 1year experience)",
    label: "Beginner level (Zero 0 or less than 1 year experience)",
  },
  {
    value: "Intermediate level (1 to 4 years experience)",
    label: "Intermediate level (1 to 4 years experience)",
  },
  {
    value: "Advanced level (4+ years of experience)",
    label: " Advanced level (5+ years of experience)",
  },
];

export const CourseInterest = [
  "AI/ML",
  "Data Science",
  "Software Development",
  "Animation",
  "Cloud Computing",
  "DevOps",
  "UI/UX",
  "Cyber Security",
  "Game Development",
  "Data Analysis & Visualization",
  "Product Management",
  "Quality Assurance",
];

export const StatusOptions = [
  { value: "", label: "Select Status" },
  { value: "New", label: "New" },
  { value: "Selected", label: "Selected" },
  { value: "Shortlisted", label: "Shortlisted" },
  {value:"Inactive", label:"Inactive"},
  // { value: "Admitted", label: "Admitted" },
  { value: "Enrolled", label: "Enrolled" },
  { value: "In Training", label: "In Training" },
  {value:"incomplete_alc_training", label:"⁠Incomplete ALC Training"},
  {value:"completed_alc_training", label:"⁠Completed ALC Training"},
  { value: "Certified", label: "Certified" },
  { value: "Employed", label: "Employed" },

];

export const ProgramOptions = [
  { value: "3mtt", label: "3MTT" },
  { value: "deeptech", label: "Deeptech" },
]


export const API_TAGS = {
  FETCH_PROVIDER_FELLOWS: "FETCH_PROVIDER_FELLOWS",
  FETCH_PROVIDER_INFO: "FETCH_PROVIDER_INFO",
  FETCH_COURSE_MODULES: "FETCH_COURSE_MODULES",
  FETCH_FELLOW_COURSE_MODULES: "FETCH_FELLOW_COURSE_MODULES",
  FETCH_FELLOW_INFO: "FETCH_FELLOW_INFO",
  FETCH_FELLOW_TEST: "FETCH_FELLOW_TEST",
  FETCH_FELLOW_QUESTIONS: "FETCH_FELLOW_QUESTIONS",
  FETCH_FELLOW_PERKS: "FETCH_FELLOW_PERKS",
  UPDATE_PROVIDER_PROFILE: "UPDATE_PROVIDER_PROFILE",
  UPDATE_FELLOW_COURSE: "UPDATE_FELLOW_COURSE",
  SUBMIT_FELLOW_TEST: "SUBMIT_FELLOW_TEST",
  SUBMIT_FELLOW_QUESTIONS: "SUBMIT_FELLOW_QUESTIONS",
  GRANT_FELLOW_CONSENT: "GRANT_FELLOW_CONSENT",
  FETCH_SELECTED_FELLOWS: "FETCH_SELECTED_FELLOWS",
  GET_PROVIDER_COURSES: "GET_PROVIDER_COURSES",
  CREATE_ASSESSMENT: "CREATE_ASSESSMENT",
  UPDATE_ASSESSMENT: "UPDATE_ASSESSMENT",
  DELETE_ASSESSMENT: "DELETE_ASSESSMENT",
  FETCH_FELLOW_ROOMS: "FETCH_FELLOW_ROOMS",
  FETCH_FELLOW_MESSAGES: "FETCH_FELLOW_MESSAGES",
  FETCH_FELLOW_NOTIFICATIONS: "FETCH_FELLOW_NOTIFICATIONS",
  UPDATE_FELLOW_NOTIFICATIONS: "UPDATE_FELLOW_NOTIFICATIONS",
  CREATE_BROADCAST: "CREATE_BROADCAST",
  FETCH_PROVIDER_BROADCASTS: "FETCH_PROVIDER_BROADCASTS",
  DELETE_BROADCAST: "DELETE_BROADCAST",
  FETCH_FAQS: ".FETCH_FAQS",
  MARK_ATTENDANCE:"MARK_ATTENDANCE",
  GET_FELLOW_ATTENDANCE:"GET_FELLOW_ATTENDANCE",
  GET_FELLOWS_ATTENDANCE:"GET_FELLOWS_ATTENDANCE",
  CONFIRM_FELLOW_ATTENDANCE:"CONFIRM_FELLOW_ATTENDANCE",
  GET_ALL_BRANCHES: "GET_ALL_BRANCHES",
  FETCH_FELLOW_EMPLOYMENT_SURVEY: "FETCH_FELLOW_EMPLOYMENT_SURVEY",
  FETCH_PROVIDER_SUBMITTED_ASSESMENTS:"FETCH_PROVIDER_SUBMITTED_ASSESMENTS",
  FETCH_ZOHO_TICKETS: "FETCH_ZOHO_TICKETS"
};

export const mapLevel = (option) => {
  let res = "";
  let _option = option?.toLowerCase();

  switch (_option) {
    case "Beginner Level (zero 0 Or Less Than 1year Experience)".toLowerCase():
    case "Beginner level (Zero 0 or less than 1 year experience)".toLowerCase():
      res = "Beginner";
      break;
    case "Intermediate Level (1 To 4 Years Experience)".toLowerCase():
    case "Intermediate".toLowerCase():
      res = "Intermediate";
      break;
    case "Advanced Level (4+ Years Of Programming Experience)".toLowerCase():
    case "Advanced level (4+ years of experience)".toLowerCase():
      res = "Advanced";
      break;
    default:
      res = "";
      break;
  }

  return res;
};

export const countWords = (sentence) => {
  const words = sentence.split(/\s+/);
  const filteredWords = words.filter((word) => word.trim() !== "");
  return filteredWords.length;
};

export const wordCountValidator = (maxWords) => {
  return Yup.string().test(
    "wordCount",
    `Maximum ${maxWords} words allowed.`,
    (value) => {
      if (!value) return true;

      const wordArray = value.trim().split(/\s+/);
      return wordArray.length <= maxWords;
    }
  );
};

export const _listItem = [
  {
    title: "3MTT Home page ",
    link: "https://3mtt.nitda.gov.ng/",
  },
  {
    title: "Requirement document for applied learning clusters",
    link: "https://drive.google.com/file/d/1D1_SRWlBM6kR_XyVGGuPhbFRp25TuCUv/view",
  },

  {
    title: "Technical Talent Training Framework",
    link: "https://b.link/1MTTReport",
  },
  {
    title:
      "Strategic Blueprint for Federal Ministry of Communications, Innovation and Digital Economy",
    link: "http://b.link/Strategic-FMoCIDE",
  },
];

export const truncateString = (str, maxLength = 30) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  } else {
    return str;
  }
};


export const telegram_links = {
  abia: "https://t.me/+hQO3gS0Wd30wYThk",
  adamawa: "https://t.me/+BOSZV4gA4GhjYzVk", 
  "akwa ibom": "https://t.me/+NPD-affFnLpiY2Zk",
  anambra: "https://t.me/+uxw2_SDhTAs0MDM0", 
  bauchi: "https://t.me/+_ZUpB77atiBkMTE0", 
  bayelsa: "https://t.me/+5vKV_JkPX1k4YmQ0",
  benue: "https://t.me/+3LEZeIKpXntjZjY0", 
  borno: "https://t.me/+ul11senZgKtlZjc0",
  "cross river": "https://t.me/+ZwX29T44scVhYWRk", 
  delta: "https://t.me/+eF5-Qc9KzdBhNzg0", 
  ebonyi: "https://t.me/+NZsurSJrM1liZmI0",
  edo: "https://t.me/+oQ-rSxtLdxdhZTQ0", 
  ekiti: "https://t.me/+_h8kG7yb9aFkYzhk",
  enugu: "https://t.me/+J4YtBfPMl81jNmM0", 
  "federal capital territory": "https://t.me/+SvvFNE5DSdllM2Jk", 
  gombe: "https://t.me/+qRfN2ZNbuPFhMTU0",
  imo: "https://t.me/+cw094df_NSNhNzk0",
  jigwa: "https://t.me/+0_3AqcKwJ9UwY2U0" ,
  kaduna: "https://t.me/+CvYAd1F05MAzMzJk", 
  kano: "https://t.me/+AD7SpMaZ8yNhMWJk",
  katsina: "https://t.me/+_cYQ-o7yLQxkZTk0",
  kebbi: "https://t.me/+MBzj9fuzDSVmNmJk",
  kogi: "https://t.me/+4PlUp63xiOI3NmFk", 
  kwara: "https://t.me/+C91fezU5kXoyZDk0",
  lagos: "https://t.me/+POvru-p332diNmQ0",
  nasarawa: "https://t.me/+eGH0_8EU_GI0MWZk",
  niger: "https://t.me/+0DZwsCNV7woxYTI0" ,
  ondo: "https://t.me/+CP734kUb6ZhkYWQ0", 
  osun: "https://t.me/+n68mRFpaVuhiNDg8",
  oyo: "https://t.me/+OvdBO8YufNdhMThk",
  plateau: "https://t.me/+lZlMphaYsKQwMjI0",
  rivers: "https://t.me/+Vfxkfv3OFgI5Nzdk",
  sokoto: "https://t.me/+wzh2GwjQa7ozNjNk", 
  taraba: "https://t.me/+JxGI0-CKrHtjMmY0" ,
  yobe: "https://t.me/+dRuNsR3cy35kZGE0" ,
  zamfara: "https://t.me/+O1vJgNmOa5wwOWM0" 
};


export const validStatuses = [
  "selected",
  "certified",
  "completed_alc_training",
  "incomplete_alc_training",
  "employed",
  "inactive",
];


export   const courses = [
  {
    name:'Career Essentials in Generative AI',
    link:'https://www.linkedin.com/learning/paths/career-essentials-in-generative-ai-by-microsoft-and-linkedin?u=104&src=direct%2Fnone&veh=direct%2Fnone',
    course:''
  },
  {
    name:'Productivity with Generative AI',
    link:'https://www.linkedin.com/learning/paths/build-your-generative-ai-productivity-skills-with-microsoft-and-linkedin?u=104&src=direct%2Fnone&veh=direct%2Fnone',
    course:''
  },
  {
    name:'Professional Soft Skills  (Mandatory)',
    link:'https://www.linkedin.com/learning/paths/career-essentials-in-soft-skills',
    course:''
  },
  {
    name:'English for IT 1  (Mandatory) ',
    link:'https://skillsforall.com/course/english-for-it1?courseLang=en-US&instance_id=1201c5e7-7059-4627-ac3e-51c4439342f1',
    course:''
  },
  {
    name:'English for IT 2',
    link:'https://skillsforall.com/course/english-for-it2?courseLang=en-US&instance_id=dc0997f5-91fd-477e-b784-e3100d0250a4',
    course:''
  },
  {
    name:'Career Essentials in Software Development',
    link:'https://www.linkedin.com/learning/paths/career-essentials-in-software-development?src=direct%2Fnone&veh=direct%2Fnone&accountId=0&u=0&success=true&authUUID=gBnL71l2Q%2FiVqj4cdrcTig%3D%3D',
    course:''
  },
  {
    name:'Career Essentials in Cybersecurity',
    link:'https://www.linkedin.com/learning/paths/career-essentials-in-cybersecurity-by-microsoft-and-linkedin?u=104&src=direct%2Fnone&veh=direct%2Fnone',
    course:''
  },
  {
    name:'Career Essentials in Systems Admin',
    link:'https://www.linkedin.com/learning/career-essentials-in-system-administration/start-your-sysadmin-journey?u=0&src=direct%2Fnone&veh=direct%2Fnone',
    course:''
  },
  {
    name:'Career Essentials in Software Development',
    link:'https://www.linkedin.com/learning/paths/career-essentials-in-software-development?src=direct%2Fnone&veh=direct%2Fnone&accountId=0&u=0&success=true&authUUID=gBnL71l2Q%2FiVqj4cdrcTig%3D%3D',
    course:''
  },
  {
    name:'Career Essentials in Project Management',
    link:'https://www.linkedin.com/learning-login/continue?account=104&forceAccount=false&authUUID=YWJ37cu%2FRpSTl6MsnsWvMg%3D%3D&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Fpaths%2Fcareer-essentials-in-project-management%3Fsrc%3Dre-other%26veh%3Dstatics.teams.cdn.office.net%257Cre-other&src=direct%2Fnone&veh=direct%2Fnone',
    course:''
  },
  {
    name:'Career Essentials in Software Development',
    link:'https://www.linkedin.com/learning/paths/career-essentials-in-software-development?src=direct%2Fnone&veh=direct%2Fnone&accountId=0&u=0&success=true&authUUID=gBnL71l2Q%2FiVqj4cdrcTig%3D%3D',
    course:''
  },
  {
    name:'UI/UX for Beginners',
    link:'https://www.mygreatlearning.com/academy/learn-for-free/courses/ui-ux',
    course:''
  },
  {
    name:'Introduction to Design Thinking',
    link:'https://www.mygreatlearning.com/academy/learn-for-free/courses/introduction-to-design-thinking',
    course:''
  },
  {
    name:'Career Essentials in Business Analyst ',
    link:'https://www.linkedin.com/learning/paths/career-essentials-in-business-analysis?src=direct%2Fnone&veh=direct%2Fnone&accountId=0&u=0&success=true&authUUID=KZClbKIISIGZo00Jobbh0A%3D%3D',
    course:''
  },
  {
    name:'Career Essentials in Data Analysis',
    link:'https://www.linkedin.com/learning/paths/career-essentials-in-data-analysis?src=direct%2Fnone&veh=direct%2Fnone&accountId=0&u=0&success=true&authUUID=BlVF0LZgQLaL66e%2FolKJ1g%3D%3D',
    course:''
  }

];


export const assesmenttStatus = ["submitted","approved", "disapproved"];
export const skills = ["Technical", "Non-technical"];
export const levels = ["Beginner", "Intermediate", 'Advanced', "Exprt"];

export const extractErrorMessage = (errors) => {
  let errorMessages = "";

  if (errors?.data?.errors) {
    for (const field in errors?.data?.errors) {
      errorMessages += `${replaceUnderscoresWithSpaces(field)}: ${errors?.data?.errors[field].join(", ")}`;
    }
    return errorMessages
  }
  else if (errors?.data?.code) {
    errorMessages = errors?.data?.detail
    return errorMessages
  }
  else {
    errorMessages = errors?.data?.message
    return errorMessages

  }

};


export const shortenWord = (word) => {
  if (word.length > 20) {
    return word.slice(0, 20) + '...';
  }
  return word;
}

  // if(errors) {
  //   for (const field in errors) {
  //     errorMessages += `${capitalize(replaceUnderscoresWithSpaces(field))}: ${errors[
  //       field
  //     ].join(", ")}`;
  //   }
  // }else {
  //   errorMessages  = "Error Occured"
  // }

  
  // return errorMessages;

export const guidelines = [
  {
    rule: "Respect Everyone",
    description: "Communicate kindly; harassment or discrimination is strictly prohibited."
  },
  {
    rule: "Protect Personal Info",
    description: "Avoid sharing personal contact details (email addresses, phone numbers) in group chats."
  },
  {
    rule: "Keep It Confidential",
    description: "Don't forward private conversations or files without permission."
  },
  {
    rule: "Report Problems",
    description: "Contact your Peer Lead or CM immediately if you feel unsafe or witness bad behavior."
  },
  {
    rule: "Use Official Channels",
    description: "Stick to the LMS, dashboard, or approved community spaces for group activities."
  },
  {
    rule: "Virtual Interactions",
    description: "All official P2P learning activities occur online or in approved community centers (if applicable). Avoid sharing private contact information (e.g., personal phone numbers, home addresses)."
  }
];

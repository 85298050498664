import { ReactComponent as PublicHeader } from "images/svg/public-leaderboaord-header.svg";
import { ReactComponent as DareyLogo } from "images/svg/darey-leaderboard-logo.svg";
import { ReactComponent as MttLogo } from "images/svg/3mtt-leaderboard-logo.svg";
import { ReactComponent as PartnerIcon } from "images/svg/partner.svg";



 

const PublicLeaderboardHeader =() =>{
    return(
        <div className="welcome-component public-leaderboard-header">
            <div className="welcome-left">
                <div className="welcome-text">
                    <h4>Leaderboard Result</h4>
                    <h6 className="public-leaderboard-welcome-text">The leaderboard displays the performance of fellows across different cohorts, based on their Darey score.</h6>
                </div>
            </div>
            <div className="public-leaderboard-logos">
            <MttLogo className="leaderboard-logo"/>
                <PartnerIcon className="leaderboard-logo"/>
                <DareyLogo className="leaderboard-logo"/>
            </div>
            <div>
                <PublicHeader className="welcome-image"/>
            </div>
        </div>
    )
    
}


export default PublicLeaderboardHeader
/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { API_TAGS } from "utils/strings";
import baseQueryWithReauth from "./baseQuery";

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["SingleUser"],
  endpoints: (builder) => ({
    getAllFellows: builder.query({
      query: (query) =>
        `provider/myfellows${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.FETCH_PROVIDER_FELLOWS],
    }),
    getFellowProfile: builder.query({
      query: () => `account/profile`,
      providesTags: [API_TAGS.FETCH_FELLOW_INFO],
    }),
    getFellowTest: builder.query({
      query: () => `fellow/questions`,
      providesTags: [API_TAGS.FETCH_FELLOW_TEST],
    }),

    getWebinars: builder.query({
      query: () => `webinar/list`,
    }),

    getFellowQuestions: builder.query({
      query: () => `fellow/general/questions`,
      providesTags: [API_TAGS.FETCH_FELLOW_QUESTIONS],
    }),

    getFellowPerks: builder.query({
      query: () => `perks`,
      providesTags: [API_TAGS.FETCH_FELLOW_PERKS],
    }),

    getFellowAppliedModules: builder.query({
      query: () => `courses/fellow/modules`,
      providesTags: [API_TAGS.FETCH_FELLOW_COURSE_MODULES],
    }),

    getFellowCourseModules: builder.query({
      query: (type) => `fellow/mycourse?fellow_type=${type}`,
      providesTags: [API_TAGS.FETCH_FELLOW_COURSE_MODULES],
    }),
    grantFellowConsent: builder.query({
      query: () => `fellow/consent`,
      providesTags: [API_TAGS.GRANT_FELLOW_CONSENT],
    }),
    getSelectedFellows: builder.query({
      query: (filterString) => `core/fellow/selected?${filterString}`,
      providesTags: [API_TAGS.FETCH_SELECTED_FELLOWS],
    }),
    rejectContract: builder.query({
      query: () => `provider/contract/reject`,
    }),
    filterFellows: builder.mutation({
      query: (body) => ({
        url: "provider/myfellows/filter",
        method: "POST",
        body,
      }),
    }),
    exportFellows: builder.mutation({
      query: (filterString) => ({
        url: `provider/myfellows/export?${filterString?.length > 0 ? `${filterString}`: ""}`,
        method: "POST",
        body: {
          type: "EXCEL"
        },
      }),
    }),
    updateFellowStatus: builder.mutation({
      query: (body) => ({
        url: `provider/myfellows/${body?.id}/update`,
        method: "PUT",
        body: {
          status: body?.status,
        },
      }),
      invalidatesTags: [API_TAGS.FETCH_PROVIDER_FELLOWS],
    }),
    updateFellowCourse: builder.mutation({
      query: (body) => ({
        url: `fellow/course/update`,
        method: "POST",
        body,
      }),
      invalidatesTags: [API_TAGS.UPDATE_FELLOW_COURSE],
    }),
    updateProviderProfile: builder.mutation({
      query: (body) => ({
        url: `account/profile`,
        method: "POST",
        body,
      }),
      invalidatesTags: [API_TAGS.UPDATE_PROVIDER_PROFILE],
    }),
    updateFellowProfile: builder.mutation({
      query: (body) => ({
        url: `account/profile`,
        method: "POST",
        body,
      }),
      invalidatesTags: [API_TAGS.FETCH_FELLOW_INFO],
    }),
    submitFellowTest: builder.mutation({
      query: (body) => ({
        url: `fellow/questions/mark`,
        method: "POST",
        body: {
          answers: {
            ...body,
          },
        },
      }),
      invalidatesTags: [API_TAGS.SUBMIT_FELLOW_TEST],
    }),
    submitFellowQuestions: builder.mutation({
      query: (body) => ({
        url: `fellow/general/questions/mark`,
        method: "POST",
        body,
      }),
      invalidatesTags: [API_TAGS.SUBMIT_FELLOW_QUESTIONS],
    }),
    getAllFAQs: builder.query({
      query: (query) => `faqs/all${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.FETCH_FAQS],
    }),
    getCohorts: builder.query({
      query: (query) => `cohorts/all?${query}`,
    }),
    getPublicCohorts: builder.query({
      query: (query) => `cohorts/list/public`
    }),
    getProviderBranches: builder.query({
      query: () => `provider/branches`,
      providesTags: [API_TAGS.GET_ALL_BRANCHES],
    }),
    createProviderBranchApplication: builder.mutation({
      query: (body) => ({
        url: `provider/branches`,
        method: "POST",
        body
      }),
      invalidatesTags: [API_TAGS.GET_ALL_BRANCHES],
      }),
    setFellowProficiency: builder.mutation({
      query: (body) => ({
        url: `provider/myfellows/${body?.id}/proficiency`,
        method: "POST",
        body
      }),
      invalidatesTags: [API_TAGS.FETCH_FELLOW_INFO]
    }),
    verifyFellowCertification: builder.query({
      query: (fellow_id) => `core/fellow/verify?fellow_id=${fellow_id}`,
    }),
    getAllIndustries: builder.query({
      query: () => `core/industries`,
    }),
    getFellowEmploymentSurvey: builder.query({
      query: () => 'fellow/emoloyment/survey',
      providesTags: [API_TAGS.FETCH_FELLOW_EMPLOYMENT_SURVEY],

    }),
    createFellowEmploymentSurvey: builder.mutation({
      query: (body) => ({
        url: 'fellow/emoloyment/survey',
        method: "POST",
        body
      }),
      invalidatesTags: [API_TAGS.FETCH_FELLOW_EMPLOYMENT_SURVEY]
    }),

    getAllJobs: builder.query({
      query: () => `core/job/links`,
    }),
    getAllGigs: builder.query({
      query: () => `admin/job/page/links`,
    }),
    getAllFellowSurveys: builder.query({
      query: () => `fellow/surveys`,
    }),
    getFellowCertificates: builder.query({
      query: () => `fellow/certificates`
    }),
    getAllOnlineLearningProviders: builder.query({
      query: () => `core/online_learning_providers`
    }),
    getAllCourses: builder.query({
      query: (query) => `courses/all${query.length > 0 ? `?${query}` : ""}`
    }),
    getAllCoursesPublic : builder.query({
      query: () => `courses/public/list`
    }),
    getStateAvailableCourses : builder.query({
      query: (query) => `courses/available/in/lga${query.length > 0 ? `?${query}` : ""}`
    }),
    getZohoSupportTickets: builder.query({
      query: () => `core/support-ticketing`
    }),
    SubmitZohoSupportTicket: builder.mutation({
      query: (body) => ({
        url: 'core/support-ticketing',
        method: "POST",
        body
      }),
      invalidatesTags: [API_TAGS.FETCH_ZOHO_TICKETS]
    }),
    joinCohortWaitlist: builder.mutation({
      query: (body) => ({
        url: 'cohorts/waitlist',
        method: "POST"
      })
    }),
    checkFellowInCohortWaitlist: builder.query({
      query: () => `cohorts/waitlist`
    }),
    getFellowCohortStats: builder.query({
      query: () => `fellow/cohort-stats`
    }),


    getAllLeaderboardFellows : builder.query({
      query: (query) => `leaderboard/fellows/all${query.length > 0 ? `?${query}` : ""}`
    }),
    getFellowPeerLearning : builder.query({
      query: () => `leaderboard/fellows/peer-learning`
    }),
    getPeerToPeerLearning : builder.query({
      query: (query) => `leaderboard/fellows/peer-peer${query.length > 0 ? `?${query}` : ""}`
    }),
    getLeaderProviderFellows : builder.query({
       query: (query) => `leaderboard/provider/fellows${query.length > 0 ? `?${query}` : ""}`
    }),
    getLeaderProviderStats : builder.query({
      query: (query) => `leaderboard/provider/fellows/stats`
   }),
   getPublicLeaderboard : builder.query({
    query: (query) => `leaderboard/public/fellows/all${query.length > 0 ? `?${query}` : ""}`
 }),
  }),
  
});

export const {
  useGetAllFellowsQuery,
  useGetFellowTestQuery,
  useGetFellowPerksQuery,
  useGetFellowQuestionsQuery,
  useGetFellowProfileQuery,
  useRegisterRiderMutation,
  useFilterFellowsMutation,
  useExportFellowsMutation,
  useUpdateFellowStatusMutation,
  useUpdateProviderProfileMutation,
  useUpdateFellowProfileMutation,
  useUpdateFellowCourseMutation,
  useSubmitFellowTestMutation,
  useSubmitFellowQuestionsMutation,
  useGetFellowCourseModulesQuery,
  useGrantFellowConsentQuery,
  useGetSelectedFellowsQuery,
  useGetFellowAppliedModulesQuery,
  useRejectContractQuery,
  useGetWebinarsQuery,
  useGetAllFAQsQuery,
  useGetCohortsQuery,
  useGetPublicCohortsQuery,
  useGetProviderBranchesQuery,
  useCreateProviderBranchApplicationMutation,
  useSetFellowProficiencyMutation,
  useLazyVerifyFellowCertificationQuery,
  useGetAllIndustriesQuery,
  useGetFellowEmploymentSurveyQuery,
  useCreateFellowEmploymentSurveyMutation,
  useGetAllJobsQuery,
  useGetAllGigsQuery,
  useGetAllFellowSurveysQuery,
  useGetFellowCertificatesQuery,
  useGetAllOnlineLearningProvidersQuery,
  useGetAllCoursesQuery,
  useGetAllCoursesPublicQuery,
  
  useGetZohoSupportTicketsQuery,
  useSubmitZohoSupportTicketMutation,

  useJoinCohortWaitlistMutation,
  useCheckFellowInCohortWaitlistQuery,
  useGetStateAvailableCoursesQuery,

  useGetFellowCohortStatsQuery,
  useGetAllLeaderboardFellowsQuery,
  useGetFellowPeerLearningQuery,
  useGetPeerToPeerLearningQuery,
  useGetLeaderProviderFellowsQuery,
  useGetLeaderProviderStatsQuery,
  useGetPublicLeaderboardQuery
} = adminApi;
/** @format */

import React from "react";


import { ReactComponent as ArrowUpIcon } from "images/svg/arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "images/svg/arrow-down.svg";
import { ReactComponent as GoldMedalIcon } from "images/svg/gold-medal.svg";
import { ReactComponent as SilverMedalIcon } from "images/svg/silver-medal.svg";
import { ReactComponent as BronzeMedalIcon } from "images/svg/bronze-medal.svg";
import { ReactComponent as NeutralIcon } from "images/svg/neutral.svg";


export const COLUMNS= (setActiveRecord) => [
    {
      Header: "Position",
      accessor: "position",
      Cell: ({ row }) => {
        const position = row.original.rank;
        let positionIcon = null;
        let directionIcon = null;
        
        if (position === 1) {
          positionIcon = <GoldMedalIcon className="position-medal" />;
        } else if (position === 2) {
          positionIcon = <SilverMedalIcon className="position-medal" />;
        } else if (position === 3) {
          positionIcon = <BronzeMedalIcon className="position-medal" />;
        }
        
        if (row.original.trend === "up") {
          directionIcon = <ArrowUpIcon className="trend-icon trend-up" />;
        } else if (row.original.trend === "down") {
          directionIcon = <ArrowDownIcon className="trend-icon trend-down" />;
        } else {
          directionIcon = <NeutralIcon className="trend-icon trend-neutral" />;
        }
        
        return (
          <div className="position-cell" onClick={() => setActiveRecord(row.original)}>
            {directionIcon}
            {positionIcon}
            <span>{position}</span>
          </div>
        );
      }
    },
    {
      Header: "Full Name",
      accessor: "fullName",
      Cell: ({ row }) => {
        return (
          <div className="fullname-cell" onClick={() => setActiveRecord(row.original)}>
            <div className="profile-image">
              <img 
                src={row.original.profileImg || "images/default-avatar.png"} 
                alt="" 
              />
            </div>
            <span>{row.original?.first_name} {row.original?.last_name}</span>
          </div>
        );
      }
    },
    {
      Header: "Darey Score",
      accessor: "average_project_score",
      Cell: ({ row }) => {
        const darey_score = row.original.darey_score;
        const percentage = ( Number(darey_score) / 100) * 100;
        return (
          <div className="score-wrapper"  onClick={() => setActiveRecord(row.original)}>
            <div className="score-circle">
              <svg width="60" height="60" viewBox="0 0 60 60">
                <circle 
                  cx="30" 
                  cy="30" 
                  r="25" 
                  className="score-circle-bg"
                />
                <circle 
                  cx="30" 
                  cy="30" 
                  r="25" 
                  className="score-circle-progress"
                  strokeDasharray={`${percentage * 1.57} 157`}
                  transform="rotate(-90 30 30)"
                />
              </svg>
              <div className="score-value">{ Math.ceil(darey_score)*10}  </div>
            </div>
          </div>
        );
      }
    },
    {
      Header: "Course",
      accessor: "course",
      Cell: ({ row }) => {
        return (
          <div className="course-cell"  onClick={() => setActiveRecord(row.original)}>
            <div className="course-main">{row.original.course_name}</div>
            {/* {<div className="course-sub">{row.original.fellow?.fellow_course?.code}</div>} */}
          </div>
        );
      }
    },
    {
      Header: "State of Residence",
      accessor: "state",
      Cell: ({ row }) => {
        return (
          <div className="course-cell"  onClick={() => setActiveRecord(row.original)} > 
            <div className="course-main">{row.original?.residence}</div>
          </div>
        );
      }
    },
    {
      Header: "Gender",
      accessor: "gender",
      Cell: ({ row }) => {
        return (
          <div className="course-cell" onClick={() => setActiveRecord(row.original)}>
            <div className="course-main">{row.original?.gender}</div>
          </div>
        );
      }
    }
  ]

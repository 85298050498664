/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { API_TAGS } from "utils/strings";
import baseQueryWithReauth from "./baseQuery";

export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    API_TAGS.FETCH_PROVIDER_BROADCASTS,
    API_TAGS.FETCH_FELLOW_NOTIFICATIONS,
  ],
  endpoints: (builder) => ({
    getFellowNotifications: builder.query({
      query: (query) =>
        `broadcasts/recieved${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.FETCH_FELLOW_NOTIFICATIONS],
    }),

    getProviderBroadcasts: builder.query({
      query: (query) =>
        `broadcasts/messages${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.FETCH_PROVIDER_BROADCASTS],
    }),

    updateFellowNotifications: builder.mutation({
      query: (body) => ({
        url: `broadcasts/messages/batch/read`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.UPDATE_FELLOW_NOTIFICATIONS],
      invalidatesTags: [API_TAGS.FETCH_FELLOW_NOTIFICATIONS],
    }),

    deleteProviderBroadcast: builder.mutation({
      query: (ID) => ({
        url: `broadcasts/messages/${ID}`,
        method: "DELETE",
      }),
      providesTags: [API_TAGS.DELETE_BROADCAST],
      invalidatesTags: [API_TAGS.FETCH_PROVIDER_BROADCASTS],
    }),

    createNewBroadcast: builder.mutation({
      query: (body) => ({
        url: `broadcasts/messages`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.CREATE_BROADCAST],
      invalidatesTags: [API_TAGS.FETCH_PROVIDER_BROADCASTS],
    }),

    getCarouselBroadcasts: builder.query({
      query: (query) => ({
        url: `broadcasts/carousels${query.length > 0 ? `?${query}` : ""}`
      })
    })
  }),
});

export const {
  useGetFellowNotificationsQuery,
  useGetProviderBroadcastsQuery,
  useUpdateFellowNotificationsMutation,
  useDeleteProviderBroadcastMutation,
  useCreateNewBroadcastMutation,
  useGetCarouselBroadcastsQuery
} = notificationApi;

/** @format */
import React from "react";
import { ReactComponent as ChartViewIcon } from "images/svg/chart.svg";
import { ReactComponent as BgIcon } from "images/svg/bg.svg";

import "../../styles/styles.scss";
import { useGetLeaderProviderStatsQuery } from "store/services/adminAPI";
import { FadeLoader } from "react-spinners";


const LeaderStats = () => {
  const { isLoading, data } = useGetLeaderProviderStatsQuery();

  return isLoading ? (
    <div className="d-flex justify-content-center align-items-center height-15 bg-white mb-5">
      <FadeLoader color="#36d7b7" />
    </div>
  ) : (
    <div className="leader-cards">
      <div className="stat-card">
        <ChartViewIcon />
        <h3 className="stat-title">Total Fellows</h3>
        <h3 className="stat-count">{data?.data?.total_number_of_fellows}</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>

      <div className="stat-card">
        <ChartViewIcon />
        <h3 className="stat-title">Highest Darey Score</h3>
        <h3 className="stat-count">{Math.round(data?.data?.highest_darey_score)}</h3>

        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>
      <div className="stat-card">
        <ChartViewIcon />
        <h3 className="stat-title">Inactive Fellows</h3>
        <h3 className="stat-count">{data?.data?.number_of_inactive_fellows}</h3>

        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>
    </div>
  );
};

export default LeaderStats;

export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "1px solid #7117E5" : "1px solid #BCC1DE",
  //   boxShadow: state.isFocused ? "0 0 0 1px #7117E5" : "none",
    borderRadius: "4px",
    padding: "5px",
    transition: "border-color 0.2s ease",
    
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "4px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? "#f0f0f0"
      : state.isSelected
      ? "red"
      : "white",
    color: state.isSelected ? "white" : "#333",
    cursor: "pointer",
    padding: "10px",
    indicatorSeparator: (styles) => ({display:'none'})
  }),
  placeholder: (provided) => ({
    ...provided,
  //   color: "#888",
    color: "fff000"
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333",
  }),
};
import React, { useRef, useEffect } from "react";
import { ReactComponent as CloseIcon } from "images/svg/close.svg";
import { ReactComponent as ComingSoonImage } from "images/svg/coming-soon-banner.svg";

const FeatureUnavailableModal = ({ isVisible, setIsVisible }) => {
  const modalRef = useRef(null);

  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1001,
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, setIsVisible]);

  if (!isVisible) return null;

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        }}
      />
      <div className="filter-modal-unavailable" style={modalStyle} ref={modalRef}>
        <>
        <div className="filter-modal-unavailable-close">
            <CloseIcon onClick={() => setIsVisible(false)}/>
            </div>
        <div className="filter-modal-content">
          <ComingSoonImage className="unavailable-image"/>
        <div className="text-center">
          <h4 className="">Coming Soon!</h4>
          <p>We are working on making the best feature for you.</p>
        </div>
  
        </div>
        </>
       
      </div>
    </>
  );
};

export default FeatureUnavailableModal;
import React from 'react';
import './styles.css';
import GoldStep from './GoldStep';
import SilverStep from './SilverStep';
import BronzeStep from './BronzeStep';
import { ReactComponent as ProfileIcon } from "images/svg/profile.svg";



const LeaderboardPodium = ({winners} ) => {

  return (
    <div className="podium-container">
      <div className="podium-column podium-second">
        <div className="winner-profile">
          <div className="avatar-container">
          <ProfileIcon className="avatar-image" />
            <div className="rank-badge">{winners[1]?.rank}</div>
          </div>
          <div className="winner-info">
            <p className="winner-name">{`${winners[1]?.first_name} ${winners[1]?.last_name}`}</p>
            <p className="winner-id">{winners[1]?.custom_fellow_id}</p>
          </div>
        </div>

        <SilverStep score={Math.round(winners[1]?.darey_score)} />
      </div>

      <div className="podium-column podium-first">
        <div className="winner-profile">
          <div className="avatar-container">
            <ProfileIcon className="avatar-image" />
            
            <div className="rank-badge">{winners[0]?.rank}</div>
          </div>
          <div className="winner-info">
          <p className="winner-name">{`${winners[0]?.first_name} ${winners[0]?.last_name}`}</p>
          <p className="winner-id">{winners[0]?.custom_fellow_id}</p>
          </div>
        </div>
        <GoldStep score={Math.round(winners[0]?.darey_score)} />
      </div>

      <div className="podium-column podium-third">
        <div className="winner-profile">
          <div className="avatar-container">
          <ProfileIcon className="avatar-image" />
            <div className="rank-badge">{winners[2]?.rank}</div>
          </div>
          <div className="winner-info">
            <p className="winner-name">{`${winners[2]?.first_name} ${winners[2]?.last_name}`}</p>
            <p className="winner-id">{winners[2]?.custom_fellow_id}</p>
          </div>
        </div>
        <BronzeStep score={Math.round(winners[2]?.darey_score)} />
      </div>
    </div>
  );
};

export default LeaderboardPodium;
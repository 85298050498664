/** @format */

import React, { Fragment, useState } from "react";
import { FadeLoader } from "react-spinners";
import FellowLayout from "../../Fellow";
import FellowLeaderboardTable from "./components/tables/FellowLeaderBoardTable";
import LeaderStats from "./components/tables/LeaderStats";
import LeaderUserTracker from "./components/tables/LeaderUserTracker";
import LeaderTabs from "./components/tables/LeaderTabs";
import { useGetAllLeaderboardFellowsQuery, useGetPeerToPeerLearningQuery } from "store/services/adminAPI";
import PeerGroupStats from "./components/PeerGroupStats";
import PeerToPeerTable from "./components/tables/PeerToPeerTable";
import PeerGroupTracker from "./components/tables/PeerGroupTracker";



const FellowLeaderBoardPage = () => {
  const [activeTab, setActiveTab] = useState("Fellows");
  const [filterString, setFilterString] = useState("");
  const [peersFilterString, setPeersFilterString] = useState("");
  const [activeRecord, setActiveRecord] = useState(null);
  

  const {
    isLoading: loadingFellows, isFetching: fetchingFellows,data: leaderboardData} = useGetAllLeaderboardFellowsQuery(
      filterString,
      { skip: activeTab !== "Fellows" }
  );

  const {isLoading: loadingP2P,isFetching: fetchingP2P,data: P2PData} = useGetPeerToPeerLearningQuery(
    peersFilterString,
    { skip: activeTab !== "Peer" }
  );

  const isCurrentTabLoading = 
    (activeTab === "Fellows" && (loadingFellows || fetchingFellows)) ||
    (activeTab === "Peer" && (loadingP2P || fetchingP2P));

  return (
    <Fragment>
      <FellowLayout>
        <LeaderTabs activeTab={activeTab} setActiveTab={setActiveTab} setActiveRecord={setActiveRecord}/>
        
        {isCurrentTabLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-xl-8">
              {activeTab === "Fellows" && (
                <div>
                  <LeaderStats data={leaderboardData?.data?.auth_user_row}/>
                  <FellowLeaderboardTable 
                    leaderboardData={leaderboardData?.data?.results}
                    metadata={leaderboardData?.data?.metadata}
                    auth_user_row={leaderboardData?.data?.auth_user_row}
                    isLoading={isCurrentTabLoading}
                    setFilterString={setFilterString}
                    setActiveRecord={setActiveRecord}
                  />
                </div>
              )}
              
              {activeTab === "Peer" && (
                <div>
                  <PeerGroupStats 
                    data={P2PData?.data?.auth_user_group}
                  />
                  <PeerToPeerTable 
                    P2Pdata={P2PData?.data?.results}
                    metadata={P2PData?.data?.metadata}
                    auth_user_group={P2PData?.data?.auth_user_group}
                    isLoading={isCurrentTabLoading}
                    setFilterString={setPeersFilterString}
                    setActiveRecord={setActiveRecord}
                  />
                </div>
              )}
            </div>
            
            <div className="col-12 col-xl-4">
              {
                activeTab === "Peer" ? (
                  <PeerGroupTracker activeRecord={activeRecord} />
                ) : (
                  <LeaderUserTracker activeRecord={activeRecord} />
                )
              }
            </div>
          </div>
        )}
      </FellowLayout>
    </Fragment>
  );
};

export default FellowLeaderBoardPage;
/** @format */
import React from "react";
import { ReactComponent as BgIcon } from "images/svg/bg.svg";
import { ReactComponent as LeaderboardRankIcon } from "images/svg/leaderboard-rank.svg";
import { ReactComponent as PeerGroupScore } from "images/svg/peer-group-score.svg";

const PeerGroupStats = ({ data }) => {
  return (
    <div className="group-stat-cards">
      <div className="peer-group-card">
        <LeaderboardRankIcon />
        <h3 className="stat-title">Peer Group Rank</h3>
        <h3 className="stat-count">{data?.position || "N/A"}</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>

      <div className="peer-group-card">
        <PeerGroupScore />
        <h3 className="stat-title">Peer Group Average Darey Score</h3>
        <h3 className="stat-count">{data?.avg_darey_score ? Math.round(data?.avg_darey_score)*10 : "N/A"}</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>
      {/* <div className="peer-group-card">
        <PeerGroupScore />
        <h3 className="stat-title">Highest Peer Group <br/>Darey Score</h3>
        <h3 className="stat-count">{data?.highest_darey_score ? Math.round(data?.highest_darey_score)*10 : "N/A"}</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div> */}
    </div>
  );
};

export default PeerGroupStats;

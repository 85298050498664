/** @format */

import React, { Fragment, useState, useEffect } from "react";
import { FadeLoader } from "react-spinners";
import { Col, Button } from "react-bootstrap";
import dayjs from "dayjs";
import FellowLayout from "../../Fellow";
import { useGetFellowAttendanceQuery } from "store/services/providerAPI";
import EmptyNotice from "component/ErrorNotice";
import { useSelector } from "react-redux";
import RatingModal from "./components/RatingModal";

const FellowAttendance = () => {
  const profileState = useSelector((state) => state.profile);
  const _profile = profileState?.profileInfo?.data?.profile || null;
  const fellowType = _profile?.current_program ?? null;

  const [skip, setSkip] = useState(true);
  const [program, setProgram] = useState(null);
  const [filter, setFilter] = useState(
    `page=1${program ? `&current_program=${program}` : ""}`
  );

  const { data, isLoading: fetchAttendance } = useGetFellowAttendanceQuery(
    filter,
    {
      skip: skip,
    }
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedAttendanceID, setSelectedAttendanceID] = useState("");

  useEffect(() => {
    if (fellowType) {
      setProgram(fellowType);
      const filterString = `page=1&current_program=${fellowType}`;
      setFilter(filterString);
      setSkip(false);
    }
  }, [fellowType]);

  const triggerModal = (Id) => {
    setShowModal(true);
    setSelectedAttendanceID(Id);
  };

  return (
    <Fragment>
      <RatingModal
        showModal={showModal}
        setShowModal={setShowModal}
        attendanceId={selectedAttendanceID}
      />

      <FellowLayout>
        {fetchAttendance ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : data ? (
          <>
            <div className="row">
              <Col xl={12}>
                <section className="custom-tab-1">
                  <div className="widget-heading d-flex justify-content-between align-items-center">
                    <h3 className="m-0">Course Attendance</h3>
                  </div>

                  {data?.data?.attendances?.length > 0 ? (
                    data?.data?.attendances.map((item, ind) => (
                      <div className="card-schedule" key={ind}>
                        <span className={`side-label bg-primary`}></span>
                        <div className="up-comming-schedule">
                          <div>
                            <h4>{item.cohort_schedule?.label}</h4>
                            <div className="mb-sm-0 mb-2">
                              <img src={item.image} className="me-2" alt="" />
                              <span>
                                {item?.fellow?.first_name}{" "}
                                {item?.fellow?.last_name}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <svg
                              className="me-1"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 2.73499H12.255V2.25C12.255 1.83999 11.92 1.5 11.505 1.5C11.09 1.5 10.755 1.83999 10.755 2.25V2.73499H8.75V2.25C8.75 1.83999 8.41501 1.5 8 1.5C7.58499 1.5 7.25 1.83999 7.25 2.25V2.73499H5.245V2.25C5.245 1.83999 4.91001 1.5 4.495 1.5C4.07999 1.5 3.745 1.83999 3.745 2.25V2.73499H3C1.48498 2.73499 0.25 3.96499 0.25 5.48498V12.75C0.25 14.265 1.48498 15.5 3 15.5H13C14.515 15.5 15.75 14.265 15.75 12.75V5.48498C15.75 3.96499 14.515 2.73499 13 2.73499ZM14.25 6.31H1.75V5.48498C1.75 4.79498 2.31 4.23499 3 4.23499H3.745V4.69C3.745 5.10498 4.07999 5.44 4.495 5.44C4.91001 5.44 5.245 5.10498 5.245 4.69V4.23499H7.25V4.69C7.25 5.10498 7.58499 5.44 8 5.44C8.41501 5.44 8.75 5.10498 8.75 4.69V4.23499H10.755V4.69C10.755 5.10498 11.09 5.44 11.505 5.44C11.92 5.44 12.255 5.10498 12.255 4.69V4.23499H13C13.69 4.23499 14.25 4.79498 14.25 5.48498V6.31Z"
                                fill="#c7c7c7"
                              />
                            </svg>
                            <span>
                              {dayjs(item.cohort_schedule?.start_date).format(
                                "MMMM D, YYYY"
                              )}{" "}
                              to{" "}
                              {dayjs(item.cohort_schedule?.end_date).format(
                                "MMMM D, YYYY"
                              )}
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <svg
                              className="me-1"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_1049_649)">
                                <path
                                  d="M8 16.25C12.275 16.25 15.75 12.775 15.75 8.5C15.75 4.22501 12.275 0.75 8 0.75C3.72501 0.75 0.25 4.22501 0.25 8.5C0.25 12.775 3.72501 16.25 8 16.25ZM7.25 4.345C7.25 3.92999 7.58499 3.595 8 3.595C8.41501 3.595 8.75 3.92999 8.75 4.345V7.75H10.5C10.915 7.75 11.25 8.08499 11.25 8.5C11.25 8.91501 10.915 9.25 10.5 9.25H8C7.58499 9.25 7.25 8.91501 7.25 8.5V4.345Z"
                                  fill="#c7c7c7"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip02_1049_649">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(0 0.5)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>

                            <span>
                              {item?.fellow_confirmed
                                ? "Attendance Confirmed"
                                : "Attendance Not Confirmed"}
                            </span>
                          </div>
                          <div>
                            <Button
                              disabled={item?.fellow_confirmed ? true : false}
                              className="btn btn-primary btn-sm"
                              onClick={() => triggerModal(item?.id)}
                            >
                              <>
                                {item?.fellow_confirmed
                                  ? "Confirmed"
                                  : "Confirm"}
                              </>
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="bg-white py-5">
                      <EmptyNotice text="No attendance yet" />
                    </div>
                  )}
                </section>
              </Col>
            </div>
          </>
        ) : null}
      </FellowLayout>
    </Fragment>
  );
};

export default FellowAttendance;

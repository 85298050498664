/** @format */

import React, {useState } from "react";
import LeaderboardPodium from "./support/components/LeaderboardPodium";
import PublicLeaderBoardTable from "./_SortingTable/PublicLeaderboardTable";
import { useGetPublicLeaderboardQuery } from "store/services/adminAPI";
import PublicLeaderboardHeader from "./support/components/PublicLeaderboardHeader";


const PublicLeaderboardPage = () => {
    const [filterString, setFilterString] = useState("");
  
    const {
      isLoading: loadingFellows, isFetching: fetchingFellows,data: leaderboardData} = useGetPublicLeaderboardQuery(
        filterString,
    );

  return (
    <div className="container">
      <PublicLeaderboardHeader/>
      {
        loadingFellows || fetchingFellows ? null : (
          <LeaderboardPodium winners={leaderboardData?.data?.top} />)
      }
      {

      }
      <PublicLeaderBoardTable 
        setFilterString={setFilterString}
        isLoading={loadingFellows || fetchingFellows}
        metadata={leaderboardData?.data?.metadata}
        leaderboardData={leaderboardData?.data?.results}
      />
    </div>
  );
};

export default PublicLeaderboardPage;

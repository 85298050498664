/** @format */

import React from "react";


import { ReactComponent as ArrowUpIcon } from "images/svg/arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "images/svg/arrow-down.svg";
import { ReactComponent as GoldMedalIcon } from "images/svg/gold-medal.svg";
import { ReactComponent as SilverMedalIcon } from "images/svg/silver-medal.svg";
import { ReactComponent as BronzeMedalIcon } from "images/svg/bronze-medal.svg";
import { ReactComponent as NeutralIcon } from "images/svg/neutral.svg";
import { truncateString } from "utils/strings";



export const COLUMNS = (setActiveRecord) => [
    {
      Header: "Position",
      accessor: "position",
      Cell: ({ row }) => {
        const position = row.original.position;
        let positionIcon = null;
        let directionIcon = null;
        
        if (position === 1) {
          positionIcon = <GoldMedalIcon className="position-medal" />;
        } else if (position === 2) {
          positionIcon = <SilverMedalIcon className="position-medal" />;
        } else if (position === 3) {
          positionIcon = <BronzeMedalIcon className="position-medal" />;
        }
        
        if (row.original.trend === "up") {
          directionIcon = <ArrowUpIcon className="trend-icon trend-up" />;
        } else if (row.original.trend === "down") {
          directionIcon = <ArrowDownIcon className="trend-icon trend-down" />;
        } else {
          directionIcon = <NeutralIcon className="trend-icon trend-neutral" />;
        }
        
        return (
          <div className="position-cell">
            {directionIcon}
            {positionIcon}
            <span>{position}</span>
          </div>
        );
      }
    },
    {
      Header: "Peer Group Name",
      accessor: "name",
      Cell: ({ row }) => {
        return (
          <div className="fullname-cell" onClick={() => setActiveRecord(row.original)}>
          <span>{truncateString(`${row.original.name}`)}</span>

        </div>
        );
      }
    },
    {
      Header: "Darey Score",
      accessor: "avg_darey_score",
      Cell: ({ value }) => {
        const percentage = (value / 100)*100;
        return (
          <div className="score-wrapper">
            <div className="score-circle">
              <svg width="60" height="60" viewBox="0 0 60 60">
                <circle 
                  cx="30" 
                  cy="30" 
                  r="25" 
                  className="score-circle-bg"
                />
                <circle 
                  cx="30" 
                  cy="30" 
                  r="25" 
                  className="score-circle-progress"
                  strokeDasharray={`${percentage * 1.57} 157`}
                  transform="rotate(-90 30 30)"
                />
              </svg>
              <div className="score-value">{Math.round(value)*10}</div>
            </div>
          </div>
        );
      }
    },

    {
      Header: "Course",
      accessor: "course",
      Cell: ({ row }) => {
        return (
          <div className="course-cell"  onClick={() => setActiveRecord(row.original)} > 
            <div className="course-main">{row.original.course__title}</div>
          </div>
          )
      }
    },
    {
      Header: "State of Residence",
      accessor: "state",
      Cell: ({ row }) => {
        return (
          <div className="course-cell"  onClick={() => setActiveRecord(row.original)} > 
            <div className="course-main">{row.original.state}</div>
          </div>
          )
      }
    },
  ]

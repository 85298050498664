/** @format */

import React, { useState, useEffect } from "react";
import ProviderLayout from "views/providers/Provider";
import ProviderFellowsTable from "../../components/table/fellows/ProviderFellowsTable";
import { useGetAllFellowsQuery } from "store/services/adminAPI";
import { FadeLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";

const ProviderFellows = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [program, setProgram] = useState(false);
  const [filter, setFilter] = useState(
    location?.search?.length > 0
      ? location?.search?.slice(1)
      : `page=1&page_size=100${program ? "&current_program=3mtt": ""}`
  );
  const [skip, setSkip] = useState(false);

  const {
    data,
    isLoading: fetchingFellows,
    isFetching,
  } = useGetAllFellowsQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      const fullPath = `${location?.pathname}?${filter}`;
      setSkip(false);
      navigate(fullPath);
    }
  }, [filter, navigate, location?.pathname]);

  useEffect(() => {
    const newFilter = `page=1&page_size=100${program ? "&current_program=deeptech" : ""}`;
    setFilter(newFilter);
  }, [program]);

  return (
    <ProviderLayout>
      <div className="row">
        {fetchingFellows ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <ProviderFellowsTable
            fellows={data?.data?.results}
            metadata={data?.data?.metadata}
            setFilter={setFilter}
            setSkip={setSkip}
            filtering={fetchingFellows || isFetching}
            filter={filter}
            setProgram={setProgram}
          />
        )}
      </div>
    </ProviderLayout>
  );
};
export default ProviderFellows;

// http://localhost:3003/provider/fellows?page=1&gender=Male&residence=Kwara&cohort_id=d24ed364-0d97-4017-b576-0bc1e0ad0020

/** @format */

import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { capitalize } from "utils/strings";
import FellowProfileForm from "./FellowProfileForm";
import ProfessionalData from "./ProfessionalData";
import useIsDeepTech from "hooks/useDeeptech";

const ProfileContent = ({ fellow }) => {
  const [activeToggle, setActiveToggle] = useState("aboutMe");
  const [editMode, setEditMode] = useState(false);
  const isDeepTech = useIsDeepTech();

  const data = fellow?.data?.profile;

  const cohort = Number(data?.cohort?.rank) ?? 0;

  const handleEditModeToggle = () => {
    setEditMode(!editMode);
  };

  return (
    <Fragment>
      {data && (
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="profile-tab">
                  <div className="custom-tab-1">
                    <ul className="nav nav-tabs">
                      <li
                        className="nav-item"
                        onClick={() => setActiveToggle("aboutMe")}
                      >
                        <Link
                          to="#about-me"
                          data-toggle="tab"
                          className={`nav-link text-pigment-green ${
                            activeToggle === "aboutMe" ? "active show" : ""
                          } text-sm`}
                        >
                          Profile Information
                        </Link>
                      </li>
                      {data?.cohort != null && data.cohort !== "" && (
                        <li
                          className="nav-item"
                          onClick={() => setActiveToggle("professionalData")}
                        >
                          <Link
                            to="#professional-data"
                            data-toggle="tab"
                            className={`nav-link text-pigment-green ${
                              activeToggle === "professionalData"
                                ? "active show"
                                : ""
                            } text-sm`}
                          >
                            Professional Data
                          </Link>
                        </li>
                      )}
                    </ul>
                    <div className="tab-content">
                      <div
                        id="about-me"
                        className={`tab-pane fade ${
                          activeToggle === "aboutMe" ? "active show" : ""
                        }`}
                      >
                        <div className="profile-about-me">
                          <div className="pt-4 border-bottom-1 pb-3"></div>
                        </div>

                        {isDeepTech ? (
                          <div className="mb-4">
                            <button
                              className="btn btn-primary small col-"
                              onClick={() => setEditMode(!editMode)}
                            >
                              {editMode ? "Discard Changes" : "Update Profile"}
                            </button>
                          </div>
                        ) : (
                          cohort !== 1 && (
                            <div className="mb-4">
                              <button
                                className="btn btn-primary small col-"
                                onClick={() => setEditMode(!editMode)}
                              >
                                {editMode
                                  ? "Discard Changes"
                                  : "Update Profile"}
                              </button>
                            </div>
                          )
                        )}

                        {editMode ? (
                          <FellowProfileForm
                            currentProfile={data}
                            toggleEditMode={handleEditModeToggle}
                          />
                        ) : (
                          <div className="profile-personal-info">
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Name<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>
                                  {capitalize(data?.first_name)}{" "}
                                  {capitalize(data?.last_name)}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Email<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>{capitalize(fellow?.data?.email)}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Address<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>{capitalize(data?.address)}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Age<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>{capitalize(data?.age)}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Course<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>
                                  {isDeepTech
                                    ? data?.deep_tech_profile?.course
                                      ? capitalize(
                                          data?.deep_tech_profile?.course?.title
                                        )
                                      : "Not Assigned"
                                    : capitalize(
                                        data?.fellow_course?.title ??
                                          data?.current_learning_course
                                      )}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Employment Status
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>
                                  {capitalize(data?.employment_status)}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Gender<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>{capitalize(data?.gender)}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Education Level
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>
                                  {capitalize(data?.level_of_education)}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Phone<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>{capitalize(data?.phone)}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  State of Residence
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>{capitalize(data?.residence)}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  LGA of Residence
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>
                                  {capitalize(data?.lga || "Not provided")}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  State of Origin
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>{capitalize(data?.state_of_origin)}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Skill Level
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>
                                  {capitalize(data?.technical_skill_level)}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {data?.cohort != null && data.cohort !== "" && (
                        <div
                          id="professional-data"
                          className={`tab-pane fade ${
                            activeToggle === "professionalData"
                              ? "active show"
                              : ""
                          }`}
                        >
                          <ProfessionalData data={data} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ProfileContent;

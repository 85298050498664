import React from 'react';

const GoldStep = ({ score }) => {
  const percentage = Math.min((score / 100) * 100, 100);
  
  return (
    <div style={{ position: 'relative' }}>
      <svg width="153" height="234" viewBox="0 0 153 234" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.3885 0H140.288L152.878 16.187H0L14.3885 0Z" fill="#B88A3F" />
        <path d="M14.3885 0H140.288L152.878 16.187H0L14.3885 0Z" fillOpacity="0.1" />
        
        <rect y="16.1865" width="152.878" height="217.626" fill="#9D6E2B" />
        <rect y="16.1865" width="152.878" height="217.626" fillOpacity="0.1" />
        
        <text x="76" y="120" textAnchor="middle" fontSize="75" fontWeight="bold" fill="#6D4B1A" fillOpacity="0.8" style={{ filter: 'drop-shadow(1px 1px 1px rgba(0,0,0,0.2))' }}>1</text>
      </svg>
      
      <div style={{
        position: 'absolute',
        bottom: '50px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '50px',
        height: '50px'
      }}>
        <svg width="50" height="50" viewBox="0 0 60 60">
          <circle 
            cx="30" 
            cy="30" 
            r="25" 
            fill="white"
            stroke="#E0E0E0"
            strokeWidth="4"
          />
          <circle 
            cx="30" 
            cy="30" 
            r="25" 
            fill="none"
            stroke="#4CAF50"
            strokeWidth="4"
            strokeDasharray="157"
            strokeDashoffset={`${157 * (1 - percentage / 100)}`}
            transform="rotate(-90 30 30)"
          />
        </svg>
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontWeight: 'bold',
          fontSize: '20px'
        }}>
          {score*10}
        </div>
      </div>
    </div>
  );
};

export default GoldStep;
/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

const DeeptechModal = ({ showPrompt, setShowPrompt }) => {
  const curriculumFeatures = [
    "Localized learning curriculum tailored to the Nigerian context.",
    "Cohort-based learning program for a collaborative educational experience.",
    "Project-based immersion to develop practical, real-world skills.",
    "Expert career mentors to guide fellows in their learning and career progression.",
    "Job-matching support network to connect fellows with relevant employment opportunities.",
  ];

  const supportInstructions = [
    "Reach out to the 3MTT Support Team by navigating to the Support Menu on your dashboard.",
    "Submit your query, and a support team member will assist you promptly."
  ];

  return (
    <Modal className="modal fade" show={showPrompt}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">DeepTech Notice</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setShowPrompt(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="d-flex flex-column">
            <p>
              The DeepTech Ready Upskilling Program is designed to empower young
              Nigerians with foundational technical skills and job-ready
              competencies in Data Science and Artificial Intelligence (AI). The
              program provides:
            </p>

            <ul className="provider-verification ">
              {curriculumFeatures.map((_data) => {
                return (
                  <li key={_data}>
                    <p>{_data}</p>
                  </li>
                );
              })}
            </ul>

            <h6>Have Questions About DeepTech?</h6>

            <ul className="provider-verification ">
              {supportInstructions.map((_data) => {
                return (
                  <li key={_data}>
                    <p>{_data}</p>
                  </li>
                );
              })}
            </ul>

            <div className="col-lg-12  w-100 d-flex justify-content-center">
              <div className="form-group mb-3">
                <button
                  type="button"
                  onClick={() => setShowPrompt(false)}
                  className="submit btn btn-pigment-green"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeeptechModal;

/** @format */

import React from "react";
// import "../styles/styles.css"
import { ReactComponent as WelcomeImg } from "images/svg/welcome.svg";

const WelcomeComponent = ({ name, setActiveGuideline, setActiveRecord }) => {
  return (
    <div className="welcome-component">
      <div className="welcome-left">
        <div className="welcome-text">
          <h4>Welcome {name}!</h4>
          <h6>Explore your peer Group below</h6>
        </div>
        <div className="welcome-button">
          <button
            onClick={() => {
              setActiveGuideline(0);
              setActiveRecord(null)
            }}
            type="button"
            className="btn btn-primary"
          >
            Read Guidelines
          </button>
        </div>
      </div>
      <div className="welcome-image">
        <WelcomeImg />
      </div>
    </div>
  );
};

export default WelcomeComponent;

/** @format */

import React from "react";
import * as Yup from "yup";
import { useRegisterUserEmailMutation } from "store/services/authAPI";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { replaceUnderscoresWithSpaces } from "utils/strings";

function RegisterValidateEmail({ setStep, setUser }) {
  const [registerUserEmail, { isLoading: authenticating }] =
    useRegisterUserEmailMutation();

  const handleSubmit = async (values) => {
    const submissionValues = {
      ...values,
      user_type: values.user_type === "DEVS_IN_GOV" ? "FELLOW" : values.user_type
    };

    await registerUserEmail(submissionValues)
      .unwrap()
      .then((data) => {
        setUser({
          ...values,
          ...data?.data,
        });
        setStep(2);
      })
      .catch((error) => {
        const errorText =
          error?.data?.code === "EMAIL_NOT_FOUND"
            ? "Visit the 3MTT Website to Apply"
            : error?.data?.message;
        const btnText =
          error?.data?.code === "EMAIL_NOT_FOUND" ? "Apply Now" : "Ok";

        Swal.fire({
          title: error?.data?.code
            ? replaceUnderscoresWithSpaces(error?.data?.code)
            : "Error Occured",
          text: errorText,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: btnText,
        }).then((result) => {
          if (result.isConfirmed) {
            if (error?.data?.code === "EMAIL_NOT_FOUND") {
              let url = "https://3mtt.nitda.gov.ng/";
              window.open(url, "_blank", "noopener,noreferrer");
            }
          }
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      user_type: "",
    },
    validationSchema: UserSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <form className="form" onSubmit={formik.handleSubmit}>
      <div className="mb-3">
        <label className="mb-1">
          <strong>Email </strong>
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          placeholder="e.g. example@domainname.com"
          {...formik.getFieldProps("email")}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="text-danger mt-2 fs-12">{formik.errors.email}</div>
        ) : null}
      </div>
      <div className="mb-3">
        <label className="mb-1">
          <strong> User Type </strong>
        </label>
        <select
          name="user_type"
          id="user_type"
          className="form-control"
          {...formik.getFieldProps("user_type")}
        >
          <option value="">Sign up as</option>
          <option value="FELLOW">A Fellow (Learner)</option>
          <option value="PROVIDER">An Applied Learning Cluster (Organization)</option>
          <option value="DEVS_IN_GOV">Devs in Gov Participant</option>
          {/* <option value="PARTNER">Training Partner</option> */}
        </select>
        {formik.touched.user_type && formik.errors.user_type ? (
          <div className="text-danger mt-2 fs-12">
            {formik.errors.user_type}
          </div>
        ) : null}
      </div>
      <div className="text-center">
        <button type="submit" className="btn btn-primary btn-block">
          {authenticating ? "Authenticating....." : "Complete Registration"}
        </button>
      </div>
    </form>
  );
}

export default RegisterValidateEmail;

const UserSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  user_type: Yup.string().required("Select User Type"),
});

/** @format */

import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import { Tab } from "react-bootstrap";
import EmptyNotice from "component/ErrorNotice";
import { useGetFellowCourseModuleQuery } from "store/services/providerAPI";
import CardModule from "views/providers/pages/Courses/components/CardModule";
import { profileSelector } from "store/reducers/ProfileReducer";
import { useSelector } from "react-redux";

const FellowAppliedContent = () => {
  const { profileInfo, isLoading: gettingProfile } =
    useSelector(profileSelector);

  const profile = profileInfo?.data?.profile
    ? profileInfo?.data?.profile
    : null;
  const fellowType = profile?.current_program ?? null;


  // eslint-disable-next-line no-unused-vars
  const [skip, setSkip] = useState(true);
  const [program, setProgram] = useState(null);
  const [filter, setFilter] = useState(
    `page=1${program ? `&current_program=${program}` : ""}`
  );
  const { data: courses, isLoading: fetchingCourses } =
    useGetFellowCourseModuleQuery(filter);

  useEffect(() => {
    if (fellowType) {
      setProgram(fellowType);
      const filterString = `page=1&current_program=${fellowType}`;
      setFilter(filterString);
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fellowType]);

  return (
    <Fragment>
      {fetchingCourses ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <Tab.Content className="pt-4">
          {!gettingProfile && profile && profile?.cohort?.rank === 2 && (
            <p>
              Your weekly Course Modules contain everything you need for your
              learning, including the assigned online course module and
              assignments/tasks. Be sure to check them regularly and complete
              all tasks as required.
            </p>
          )}

          <Row className="row be">
            <Col xl="12" className="course-modules">
              <Card className="pb-5">
                <Card.Header className="d-flex justify-content-between align-items-center mb-5">
                  <Card.Title>Course Modules</Card.Title>
                </Card.Header>

                {courses && courses?.data?.length > 0 ? (
                  <div className="row">
                    {courses.data.map((course) => {
                      return <CardModule data={course} key={course?.id} />;
                    })}
                  </div>
                ) : (
                  <div className="bg-white py-5">
                    <EmptyNotice text="No module at the moment" />
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </Tab.Content>
      )}
    </Fragment>
  );
};

export default FellowAppliedContent;

/** @format */

import React from "react";

import { ReactComponent as NeutralIcon } from "images/svg/neutral.svg";
import {ReactComponent as PeerLeader} from "images/svg/pl.svg";
import {ReactComponent as DeputyPeerLeader} from "images/svg/dpl.svg";

export const COLUMNS = (setActiveRecord) => {
  const assignPositions = (data) => {
    const sortedData = [...data].sort((a, b) => (b.darey_score || 0) - (a.darey_score || 0));
    
    const positionMap = new Map();
    
    sortedData.forEach((user, index) => {
      if (index === 0) {
        positionMap.set(user.id, 'PL');
      } else if (index === 1) {
        positionMap.set(user.id, 'DPL');
      } else {
        positionMap.set(user.id, user.position || 'neutral');
      }
    });
    
    return positionMap;
  };

  return [
    {
      Header: "S/N",
      accessor: "sn",
      Cell: ({ row }) => { 
        const index = row?.index + 1
        return index
      }
    },
    {
      Header: "Full Name",
      accessor: "fullName",
      Cell: ({ row }) => {
        return (
          <div className="fullname-cell" onClick={() => setActiveRecord(row.original)}>
            <span>{`${row?.original?.first_name}  ${row?.original?.last_name}`}</span>
          </div>
        );
      }
    },
    {
      Header: "Position",
      accessor: "position",
      Cell: ({ row, data }) => {
         const positionMap = assignPositions(data);
        const position = positionMap.get(row.original.id);
        
        let positionIcon = null;
        
        if (position === "PL") {
          positionIcon = <PeerLeader className="position-medal" />;
        } else if (position === "DPL") {
          positionIcon = <DeputyPeerLeader className="position-medal" />;
        } else {
          positionIcon = <NeutralIcon className="position-medal" />;
        }
        
        return (
          <div className="position-cell" onClick={() => setActiveRecord(row.original)}>
            {positionIcon}
            <span>{position}</span>
          </div>
        );
      }
    },
    {
      Header: "Darey Score",
      accessor: "darey_score",
      Cell: ({ value }) => {
        const percentage = (value / 100) * 100;
        return (
          <div className="score-wrapper">
            <div className="score-circle">
              <svg width="60" height="60" viewBox="0 0 60 60">
                <circle 
                  cx="30" 
                  cy="30" 
                  r="25" 
                  className="score-circle-bg"
                />
                <circle 
                  cx="30" 
                  cy="30" 
                  r="25" 
                  className="score-circle-progress"
                  strokeDasharray={`${percentage * 1.57} 157`}
                  transform="rotate(-90 30 30)"
                />
              </svg>
              <div className="score-value">{Math.round(value)*10}</div>
            </div>
          </div>
        );
      }
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => {
        const email = row?.original?.email;
        return (
          <div className="course-cell"  onClick={() => setActiveRecord(row.original)} > 
            <div className="course-main">{email || "N/A"}</div>
          </div>
        )
      }
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ row }) => {
        const phone = row?.original?.phone;
        return (
          <div className="course-cell"  onClick={() => setActiveRecord(row.original)} > 
            <div className="course-main">{phone || "N/A"}</div>
          </div>
        )
      }
    }
  ]
}
/** @format */

import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { Button } from "react-bootstrap";

import { extractFilterValues } from "utils/functions";
import { COLUMNS } from "./helpers/Columns/Columns";
import "./helpers/filtering.css";
import FilterModal from "./helpers/FilterModal";
import swal from "sweetalert";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";
import { useExportFellowsMutation } from "store/services/adminAPI";
import { AttendanceModal } from "./helpers/AttendanceModal";
import {
  useGetCohortsQuery,
  useGetAllCoursesQuery,
} from "store/services/adminAPI";
import { StatusUpdateModal } from "./helpers/StatusUpdateModal";

export const ProviderFellowsTable = ({
  fellows,
  metadata,
  setFilter,
  filter,
  setSkip,
  filtering,
  setProgram
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [modalFilter, setModalFilter] = useState(null);
  const [clear, setClear] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [action, setAction] = useState("");
  const [isDeeptechSelected, setIsDeeptechSelected] = useState(false);

  const [exportFellows, { isLoading: exporting }] = useExportFellowsMutation();
  const { data: cohorts } = useGetCohortsQuery("");
  const { data: courses } = useGetAllCoursesQuery();

  const handleRowSelection = useCallback(
    (id) => {
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
      } else {
        setSelectedIds([...selectedIds, id]);
      }
    },
    [selectedIds]
  );

  const handleSelectAll = () => {
    if (!selectAll) {
      const allIds = fellows.map((_F) => _F?.id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
    setSelectAll(!selectAll);
  };

  const makeFilterString = (values, _page) => {
    const queryString = `page=${_page}&page_size=${metadata?.page_size}${
      values?.status?.length > 0 ? `&status=${values?.status?.join(",")}` : ""
    }${values?.gender?.length > 0 ? `&gender=${values?.gender}` : ""}${
      values?.selectedStates?.length > 0
        ? `&residence=${values?.selectedStates?.join(",")}`
        : ""
    }${
      values?.selectedLGAs?.length > 0
        ? `&lga=${values?.selectedLGAs?.join(",")}`
        : ""
    }${
      values?.selectedCourse?.length > 0
        ? `&course=${values?.selectedCourse?.join(",")}`
        : ""
    }${values?.cohort_id?.length > 0 ? `&cohort_id=${values?.cohort_id}` : ""}${values?.program?.length > 0 ? `&current_program=${values?.program}` : ""}`
    ;
    return queryString;
  };

  const handleAllFilter = (_modalFilter, _page) => {
    const values = extractFilterValues(_modalFilter);
    const filterString = makeFilterString(values, _page);
    setFiltered(true);
    setFilter(filterString);
    setShowFilter(false);
  };

  const handleFellowsExport = async () => {
    let exportFilterString = "";
    if (modalFilter === null) {
      exportFilterString = "";
    } else {
      const values = extractFilterValues(modalFilter);
      exportFilterString = [
        values?.status?.length > 0
          ? `status=${values?.status?.join(",")}`
          : null,
        values?.gender?.length > 0 ? `gender=${values?.gender}` : null,
        values?.selectedStates?.length > 0
          ? `state_of_origin=${values?.selectedStates?.join(",")}`
          : null,
        values?.selectedLGAs?.length > 0
          ? `lga=${values?.selectedLGAs?.join(",")}`
          : null,
        values?.selectedCourse?.length > 0
          ? `course=${values?.selectedCourse?.join(",")}`
          : null,
        values?.cohort_id?.length > 0 ? `cohort_id=${values?.cohort_id}` : null,
      ]
        .filter(Boolean)
        .join("&");
    }

    await exportFellows(exportFilterString)
      .unwrap()
      .then((data) => {
        swal({
          title: "Request Received",
          text: data?.message,
          buttons: {
            confirm: {
              text: "Close",
              value: true,
              visible: true,
              className: "btn btn-success btn-block",
              closeModal: true,
            },
          },
          icon: "success",
        });
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: "Error occured exporting records",
          buttons: {
            confirm: {
              text: "Try Again",
              value: true,
              visible: true,
              className: "btn btn-danger btn-block",
              closeModal: true,
            },
          },
          icon: "error",
        });
      });
  };

  useEffect(() => {
    if (modalFilter) {
      setSearch("");
      handleAllFilter(modalFilter, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  useEffect(() => {
    setData([...fellows]);
    setSkip(true);
  }, [fellows, setSkip]);

  useEffect(() => {
    if (filter) {
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const columns = useMemo(
    () => COLUMNS(selectedIds, handleRowSelection, cohorts),
    [selectedIds, handleRowSelection, cohorts]
  );


  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const restoreFellows = () => {
    setFilter(`page=1&page_size=100${isDeeptechSelected ? `&current_program=deeptech`: ""}`);
    setModalFilter(null);
    setClear(true);
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  return (
    <>
      <FilterModal
        setFilter={setModalFilter}
        setShowFilter={setShowFilter}
        showModal={showFilter}
        filtering={filtering}
        restoreFellows={restoreFellows}
        clearFilter={clear}
        cohorts={cohorts?.data?.cohorts}
        courses={courses?.data?.courses}
        setProgram={setIsDeeptechSelected}
      />

      <div className="card">
        <div className="card-header">
          <div className="d-flex  align-items-center">
            <div className="mr-2">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={() => handleSelectAll()}
              />
            </div>
            <div className="d-flex align-items-center">
              <h4 className="card-title text-pigment-green">All Fellows</h4>

      </div>
            {selectedIds?.length > 0 && (
              <div className="ml-2">
                <select
                  className="form-control bulk-select"
                  id="action"
                  value={action}
                  onChange={(e) => setAction(e.target.value)}
                >
                  <option value="">Choose Bulk Option</option>
                  <option value="mark_attendance">Mark Attendance</option>
                  <option value="status_update">Update status</option>
                </select>
              </div>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div className="dataTables_wrapper">
              <div className="d-flex justify-content-between mb-5">
                <div>
                  {(filtered || searched) && (
                    <button
                      onClick={() => {
                        setSearch("");
                        setSearched(false);
                        setFiltered(false);
                        setClear(!clear);
                        setFilter(`page=1`);
                      }}
                      className="btn text-danger"
                    >
                      Reset Filter
                    </button>
                  )}
                </div>

                <div className="d-flex">
                  <div className="d-flex  align-items-center">
                    <input
                      className="ml-2 input-search form-control w-100"
                      value={search}
                      onChange={handleInputChange}
                      placeholder="Search by name or email or fellow ID"
                    />

                    <button
                      type="button"
                      className="btn btn-outline ms-2 "
                      onClick={() => {
                        if (search?.length > 0) {
                          setClear(!clear);
                          setFilter(`page=1&search=${search}`);
                          setFiltered(false);
                          setSearched(true);
                        }
                      }}
                    >
                      Search
                    </button>
                  </div>

                  <div>
                    <Button
                      className="ms-2  py-3 btn-pigment-green-outline "
                      style={{ width: "100% !important" }}
                      onClick={() => setShowFilter(true)}
                    >
                      Filter
                    </Button>

                    <Button
                      onClick={() => handleFellowsExport()}
                      className="ms-2 pull-right  py-3 btn-pigment-green"
                    >
                      {exporting ? (
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm ms-2"
                        ></span>
                      ) : (
                        "Export"
                      )}
                    </Button>
                  </div>
                </div>
              </div>

              {filtering ? (
                <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
                  <FadeLoader color="#36d7b7" />
                </div>
              ) : (
                <>
                  {data.length > 0 ? (
                    <>
                      <table
                        {...getTableProps()}
                        className="table dataTable display"
                      >
                        <thead>
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                >
                                  {column.render("Header")}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {rows.map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                  return (
                                    <td {...cell.getCellProps()}>
                                      {" "}
                                      {cell.render("Cell")}{" "}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                        <button
                          className={`btn btn-outline ${
                            hasPrev ? "" : "disabled"
                          } `}
                          onClick={() => {
                            const _newPage = Number(metadata?.page - 1) || 1;
                            // if (modalFilter) {
                            //   handleAllFilter(modalFilter, _newPage);
                            // } else {
                            //   setFilter(`page=${_newPage}&page_size=${metadata?.page_size}`);
                            // }

                            const modalFilterString = modalFilter
                              ? makeFilterString(modalFilter, _newPage)
                              : "";

                            modalFilter
                              ? setFilter(modalFilterString)
                              : setFilter(
                                  `page=${_newPage}&page_size=${metadata?.page_size}`
                                );
                          }}
                        >
                          Previous
                        </button>

                        {metadata?.count && (
                          <div className="d-flex">
                            <p className="mb-0">
                              Page <strong>{metadata?.page}</strong> of{" "}
                              <strong>
                                {Math.ceil(metadata?.count / 100)}
                              </strong>{" "}
                              {/* <strong>{Math.ceil(metadata?.count / 1)}</strong>{" "} */}
                              &nbsp;
                            </p>
                            <p className="mb-0">
                              Total <strong>{metadata?.count}</strong>
                            </p>
                          </div>
                        )}

                        <button
                          className={`btn btn-outline ${
                            hasNext ? "" : "disabled"
                          } `}
                          onClick={() => {
                            const _newPage = Number(metadata?.page || 0) + 1;
                            const modalFilterString = modalFilter
                              ? makeFilterString(modalFilter, _newPage)
                              : "";

                            modalFilter
                              ? setFilter(modalFilterString)
                              : setFilter(
                                  `page=${_newPage}&page_size=${metadata?.page_size}`
                                );
                          }}
                        >
                          Next
                        </button>
                      </div>

                      <AttendanceModal
                        action={action}
                        setAction={setAction}
                        selectedIds={selectedIds}
                        cohorts={cohorts?.data?.cohorts}
                        setSelectedIds={setSelectedIds}
                      />

                      <StatusUpdateModal
                        action={action}
                        setAction={setAction}
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                      />
                    </>
                  ) : (
                    <div className="card">
                      <div className="card-body text-center ai-icon  text-primary">
                        <BagIcon />
                        <h4 className="my-2">
                          No fellows for the specified filter
                        </h4>
                        <button
                          onClick={() => setShowFilter(true)}
                          className="btn my-2 btn-primary btn-lg px-4"
                        >
                          Filter
                        </button>
                        <button
                          onClick={() => restoreFellows()}
                          className="btn my-2  btn-pigment-green-outline btn-lg px-4 mx-2"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProviderFellowsTable;

/** @format */

import React, { Fragment, useEffect, useState } from "react";
import { FadeLoader } from "react-spinners";
import { useSelector, useDispatch } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";
import { getProviderProfile } from "store/actions/profile";
import { Col, Tab, Nav } from "react-bootstrap";

import FellowLayout from "../../Fellow";
import DeepTechFaqs from "./containers/DeepFaqs";
import SelectedFaqs from "./containers/SelectedFaqs";
import CommunityFaqs from "./containers/CommunityFaqs";

import { useGetAllFAQsQuery } from "store/services/adminAPI";

const FellowFaqs = () => {

  const { profileInfo, isLoading } = useSelector(profileSelector);
  const profile = profileInfo?.data?.profile;
  const fellowType = profile?.current_program ?? null;


  const dispatch = useDispatch();
  const [activeTab, setActive] = useState("selected");

  const [skip, setSkip] = useState(true);
  const [program, setProgram] = useState(null);
  const [filter, setFilter] = useState(
    `page=1${program ? `&program=${program}` : ""}`
  );

  const { data, isLoading: fetchingFAQs } = useGetAllFAQsQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    dispatch(getProviderProfile());
  }, [dispatch]);

    useEffect(() => {
      if(fellowType)  {
        setProgram(fellowType);
        const filterString = `page=1&program=${fellowType}`;
        setFilter(filterString);
        setSkip(false)
      }
    }, [fellowType]);

 

  const tabData = [
    {
      name: "Selected Fellows",
      title: "selected",
    },
    {
      name: "Learning Community",
      title: "community",
    },
    {
      name: "Deeptech",
      title: "deeptech",
    },
  ];


  return (
    <Fragment>
      <FellowLayout>
        {fetchingFAQs || isLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : profile ? (
          <>
            <div className="widget-heading d-flex justify-content-between align-items-center">
              <h3 className="m-0">Frequently Asked Questions</h3>
            </div>

            <div className="row">
              <Col xl={12}>
                <section className="custom-tab-1">
                  <Tab.Container
                    defaultActiveKey={tabData[0].name.toLowerCase()}
                  >
                    <Nav as="ul" className="nav-tabs">
                      {tabData.map((data) => (
                        <Nav.Item
                          as="li"
                          key={data.title}
                          onClick={() => setActive(data.title)}
                        >
                          <Nav.Link eventKey={data.name.toLowerCase()}>
                            {data.name}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>

                    {activeTab === "selected" && (
                      <SelectedFaqs
                        faqs={data?.data?.filter(
                          (item) => item.faq_type.name === "Selected Fellows"
                        )}
                      />
                    )}
                    {activeTab === "community" && (
                      <CommunityFaqs
                        faqs={data?.data?.filter(
                          (item) => item.faq_type.name === "Learning Community"
                        )}
                      />
                    )}

                    {activeTab === "deeptech" && (
                      <DeepTechFaqs
                        faqs={data?.data?.filter(
                          (item) => item.program === "deeptech"
                        )}
                      />
                    )}
                  </Tab.Container>
                </section>
              </Col>
            </div>
          </>
        ) : null}
      </FellowLayout>
    </Fragment>
  );
};

export default FellowFaqs;

/** @format */
import React from "react";
import { ReactComponent as BgIcon } from "images/svg/bg.svg";
import { ReactComponent as LeaderboardRankIcon } from "images/svg/leaderboard-rank.svg";
// import { ReactComponent as CoursesIcon } from "images/svg/courses.svg";
import { ReactComponent as PeerGroupScore } from "images/svg/peer-group-score.svg";
// import { ReactComponent as SubmittedIcon } from "images/svg/submitted.svg";
// import { ReactComponent as LearningTimeIcon } from "images/svg/learning-time.svg";
import "../../styles/styles.scss";

const LeaderStats = ({ data }) => {
  return (
    <div className="group-stat-cards">
      <div className="peer-group-card">
        <LeaderboardRankIcon />
        <h3 className="stat-title">Leaderboard Rank</h3>
        <h3 className="stat-count">{data?.rank}</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>

      <div className="peer-group-card">
        <PeerGroupScore />
        <h3 className="stat-title">Darey Score</h3>
        <h3 className="stat-count">{Math.round(data?.darey_score)*10}</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>
      {/* <div className="stat-card">
        <CoursesIcon />
        <h3 className="stat-title">Courses <br/>Completed</h3>
        <h3 className="stat-count">N/A</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>
      <div className="stat-card">
        <SubmittedIcon />
        <h3 className="stat-title">Projects <br/> Submitted</h3>
        <h3 className="stat-count">N/A</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>
      <div className="stat-card">
        <LearningTimeIcon />
        <h3 className="stat-title">Weekly <br/> Learning Time</h3>
        <h3 className="stat-count">N/A</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div> */}
    </div>
  );
};

export default LeaderStats;

/** @format */
import React from "react";
import { ReactComponent as RespectIcon } from "images/svg/respect.svg";
import "../styles/styles.scss";
import { guidelines } from "utils/strings";

const PeerGuidleines = ({ activeGuideline, setActiveGuideline }) => {
  return (
    <div className="peer-guidelines">
      <h2>3MTT Peer-to-Peer Guidelines</h2>

      {guidelines.map((guideline, index) => {
        return (
          <div
            className={`guideline ${index === activeGuideline ? "active" : ""}`}
            key={index}
          >
            <div className="svg-icon">
              <RespectIcon />
            </div>

            <div className="profile-stats">
              <h4>{guideline?.rule}</h4>
              <p>{guideline?.description}</p>
            </div>
          </div>
        );
      })}

      {activeGuideline > -1 && (
        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            className="btn btn-primary-outline ml"
            onClick={() => {
              setActiveGuideline(
                (prevIndex) =>
                  (prevIndex - 1 + guidelines.length) % guidelines.length
              );
            }}
          >
            Previous
          </button>
          <button
            type="button"
            className="btn btn-primary ml"
            onClick={() => {
              setActiveGuideline(
                (prevIndex) => (prevIndex + 1) % guidelines.length
              );
            }}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default PeerGuidleines;

/** @format */

import React, { Fragment, useEffect, useState } from "react";
import { FadeLoader } from "react-spinners";
import { useSelector, useDispatch } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";
import { getProviderProfile } from "store/actions/profile";
import { capitalize, mapLevel } from "utils/strings";
import { Col, Tab } from "react-bootstrap";
import jsPDF from "jspdf";
import img from "images/3MTT/cer.png";
import FellowLayout from "../../Fellow";
import FellowOnlineContent from "./containers/OnlineContent";
import FellowAppliedContent from "./containers/AppliedContent";
import useAllowedFellow from "hooks/useAllowedFellow";
import { useSearchParams } from "react-router-dom";
import useIsDeepTech from "hooks/useDeeptech";
import "./fonts/Lato-Bold-bold";
import "./fonts/LavishlyYours-Regular-bold";
import "./fonts/PTSansNarrow-Bold-bold";

const FellowCourse = () => {
  const isDeepTech = useIsDeepTech();
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  const [activeTab, setActive] = useState(searchParams.get("course"));
  useAllowedFellow();

  useEffect(() => {
    dispatch(getProviderProfile());
  }, [dispatch]);

  const { profileInfo, isLoading } = useSelector(profileSelector);
  const profile = profileInfo?.data?.profile;

  useEffect(() => {
    if (searchParams) {
      setActive(searchParams.get("course"));
    }
  }, [searchParams]);

  const tabData = [
    {
      name: "Online Learning Content",
      title: "online",
    },
    {
      name: "Applied Learning Content",
      title: "applied",
    },
  ];

  const generateCertificate = () => {
    const deepTech = isDeepTech ? profile?.deep_tech_profile : null;
    const doc = new jsPDF({
      orientation: "portrait",
      precision: 100,
    });

    doc.addImage(
      img,
      "JPG",
      0,
      0,
      doc.internal.pageSize.getWidth(),
      doc.internal.pageSize.getHeight()
    );

    doc.setFont("helvetica");

    doc.setFontSize(18);
    doc.setTextColor("#0C4F2E");

    doc.setFont("PTSansNarrow-Bold", "bold");
    doc.text(
      `${capitalize(profile?.first_name)} ${capitalize(profile?.last_name)} `,
      120,
      132,
      {
        align: "center",
      }
    );

    doc.setFont("Lato-Bold", "bold");
    doc.setFontSize(10);
    doc.setTextColor("#000000");
    doc.text(profile?.fellow_profile_id, 78, 150, { align: "center" });

    doc.text(
      isDeepTech
        ? deepTech?.course?.title?.toUpperCase()
        : profile?.fellow_course?.title?.toUpperCase(),
      62,
      192
    );
    doc.text(
      isDeepTech
        ? deepTech?.provider?.name?.toUpperCase()
        : profile?.provider?.name?.toUpperCase(),
      62,
      228
    );

    doc.setTextColor("#0C4F2E");
    doc.text("1ST", 65, 250, { align: "center" });
    doc.text("DECEMBER, ", 79, 250, { align: "center" });
    doc.text("2024", 94, 250, { align: "center" });

    doc.save(
      `${profile?.first_name}-${profile?.last_name}-${
        isDeepTech
          ? deepTech?.course?.title?.toUpperCase()
          : profile?.fellow_course?.title
      }.pdf`
    );
  };

  return (
    <Fragment>
      <FellowLayout>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : profile ? (
          <>
            <div className="widget-heading d-flex justify-content-between align-items-center mb-3">
              <div>
                <h3 className="m-0">
                  {" "}
                  {isDeepTech
                    ? capitalize(profile?.deep_tech_profile?.course?.title)
                    : capitalize(profile?.fellow_course?.title)}
                </h3>

                <progress
                  value={
                    profile?.training_progress === null
                      ? 0
                      : profile?.training_progress / 100
                  }
                  style={{ accentColor: "#26a65b" }}
                />
                <h6 className="m-0">
                  {profile?.training_progress !== null
                    ? `${Math.round(profile?.training_progress)}% `
                    : "0% "}{" "}
                  Completed
                </h6>
              </div>

              <div className="d-flex align-items-center gap-x-4">
                <span class="badge badge-pill badge-info badge-lg">
                  {mapLevel(profile?.technical_skill_level)}
                </span>
                {profile?.status?.toLowerCase() === "certified" && (
                  <div className=" ml-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => generateCertificate()}
                    >
                      Generate Certificate
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="widget-heading d-flex justify-content-between align-items-center">
              <h3 className="m-0">
                {activeTab === "online"
                  ? "Online Learning Content"
                  : "Applied Learning Content"}
              </h3>
            </div> */}

            <div className="row">
              <Col xl={12}>
                <section className="custom-tab-1">
                  <Tab.Container
                    defaultActiveKey={tabData[0].name.toLowerCase()}
                  >
                    {activeTab === "online" && <FellowOnlineContent />}
                    {activeTab === "applied" && <FellowAppliedContent />}
                  </Tab.Container>
                </section>
              </Col>
            </div>
          </>
        ) : null}
      </FellowLayout>
    </Fragment>
  );
};

export default FellowCourse;

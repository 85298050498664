/** @format */

import { useGetFellowCourseModulesQuery } from "store/services/adminAPI";
import React, { Fragment } from "react";
import { FadeLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { Tab, Alert } from "react-bootstrap";
import EmptyNotice from "component/ErrorNotice";
import course1 from "images/courses/course1.jpg";
import { capitalize } from "utils/strings";
import { ReactComponent as InfoIcon } from "images/svg/info.svg";
import useIsDeepTech from "hooks/useDeeptech";
import { useSelector } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";

const FellowOnlineContent = () => {
  const isDeeptech = useIsDeepTech();
  const { data: courses, isLoading: fetchingCourses } =
    useGetFellowCourseModulesQuery("main");

    const { profileInfo } = useSelector(profileSelector);
    const profile = profileInfo?.data?.profile;
  // console.log("courses", courses?.data);

  return (
    <Fragment>
      {fetchingCourses ? (
        <>
          <p className="pt-4">&nbsp;</p>
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        </>
      ) : (
        <Tab.Content className="pt-4">
          <>
            {!isDeeptech && profile?.cohort?.rank !==3 && (
              <div className="col-xl-12 col-xxl-12 mb-4">
                <Alert
                  variant="success"
                  className="alert-dismissible fade show"
                >
                  <InfoIcon />
                  <a
                    href="https://ihstowers.csod.com/login/render.aspx?id=3mttacademy&ReturnUrl="
                    className="text-black"
                  >
                    Access world-class technical and professional training with
                    IHS Learning Academy.{" "}
                    <span className="text-decoration-underline">
                      Sign up here!
                    </span>
                  </a>
                </Alert>
              </div>
            )}

            {isDeeptech ? (
              <p>
                Welcome to your learning journey! To access your classroom,
                simply click on any of the links below. Explore the materials,
                engage with your peers, and make the most of this experience.
              </p>
            ) : (
              profile?.cohort?.rank === 3 ? (
                <p>
                  Welcome! You're now embarking on your online learning journey. You can access your courses on {" "}
                  <a
                    href="https://darey.io"
                    className="text-primary"
                    target="blank"
                  >
                    Darey.io
                  </a>. Click the link to log in and get started.
                </p>
              ) : (
                <p>
                  Welcome! You're now embarking on your online learning journey,
                  beginning with the foundational courses. Click the links below
                  to access your courses while you wait to be assigned to your
                  Applied Learning Cluster and make sure to{" "}
                  <a
                    href="https://forms.gle/KGUnkSygUVuKtSbV7"
                    className="text-primary"
                    target="blank"
                  >
                    fill this form{" "}
                  </a>
                  to redeem your incentive after completing all your introductory
                  courses.
                </p>
              )
            )}
              
            {courses && (
              <div className="row">
                {courses?.data?.length > 0 ? (
                  courses?.data?.map((data, index) => {
                    const course = data?.categories?.map((_) => _?.title);
                    const cohort = data?.cohorts?.map((_) => _?.rank);
                    const is_completed = data?.is_completed;

                    return (
                      <div className="col-xl-4 col-md-6" key={index}>
                        <div className="card all-crs-wid">
                          <div className="card-body">
                            <div className="courses-bx">
                              <div className="dlab-media">
                                <img
                                  src={data?.image ? data?.image : course1}
                                  alt=""
                                  className="contain-img"
                                />
                              </div>
                              <div className="dlab-info">
                                <div className="dlab-title d-flex justify-content-between">
                                  <div>
                                    <h4>
                                      <Link to={data?.link} target="_blank">
                                        { capitalize(data?.name)}
                                      </Link>
                                    </h4>
                                    {/* <p className="m-0">
                                      {capitalize(data?.topic_level)}{" "}
                                      {data?.level_of_difficulty &&
                                        `for ${capitalize(
                                          data?.level_of_difficulty
                                        )}`}
                                    </p> */}
                                    <p className="m-0">
                                      Cohort {cohort?.length > 1 ? "s" : ""}:{" "}
                                      {cohort?.join(",")}
                                    </p>
                                    <p className="m-0">
                                      Course
                                      {course.length > 1 ? "s" : ""}:{" "}
                                      {course.join(",")}
                                    </p>
                                    <div className="ai-icon">
                                      {is_completed ? (
                                        <>
                                          <i className="bi bi-patch-check me-1"></i>
                                          <span className="mb-0">
                                            Completed
                                          </span>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between content align-items-center">
                                  <Link
                                    to={data?.link}
                                    target="_blank"
                                    className="btn btn-primary btn-sm"
                                  >
                                    Go to Class
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="bg-white py-5 px-5 text-center">
                    <EmptyNotice text="No content at the moment" />
                    <p className="">
                      Your Course will be accessible as soon as your Cohort
                      commences. While you wait, you can access our free
                      Community Resourses
                      <a
                        href="https://drive.google.com/file/d/10nXdm2poFssTLk-q5J8lb0SeoasK3mUV/view?usp=sharing"
                        target="blank"
                        className="text-primary"
                      >
                        {" "}
                        here
                      </a>
                    </p>
                  </div>
                )}
              </div>
            )}
          </>
        </Tab.Content>
      )}
    </Fragment>
  );
};

export default FellowOnlineContent;

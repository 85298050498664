/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Path } from "utils/constant.data";

const DeeptechCoursesModal = ({ showPrompt, setShowPrompt }) => {
    const navigate = useNavigate();
  

  const curriculumFeatures = [
    {
      title: "Advanced Data Analysis & Visualization",
      description: "Learn how to analyze complex data and create powerful visual stories using Python, R, and Power BI. Perfect for those who love working with numbers and graphics."
    },
    {
      title: "Data Science & Machine Learning",
      description: "Dive into AI and predictive modeling, covering data processing, machine learning techniques, and real-world AI applications. Ideal if you want to build AI-powered solutions."
    },
    {
      title: "Geospatial Data Science",
      description: "Explore maps and location-based insights for urban planning, climate studies, and logistics. If you enjoy working with geographic data, this is for you."
    },
    {
      title: "Data Architecture",
      description: "Master the art of designing scalable data systems, pipelines, and storage solutions. A great choice for those who love structuring and managing big data efficiently."
    },
    {
      title: "Computer Vision",
      description: "Train AI to understand images and videos, including facial detection, image recognition, and object tracking. Perfect for those interested in AI-powered visual applications."
    },
    {
      title: "Mathematics & Advanced Machine Learning",
      description: "Go deep into the math behind AI, covering linear algebra, probability, and optimization to build high-performance machine learning models. Ideal for those who enjoy theory and algorithms."
    },
    {
      title: "Natural Language Processing (NLP)",
      description: "Teach computers to understand human language! Learn about chatbots, sentiment analysis, machine translation, and AI-driven text analysis. If you love working with words and AI, this is for you."
    }
  ];
  

  return (
    <Modal className="modal fade" show={showPrompt}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Choose One to Access Your Dashboard!</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setShowPrompt(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="d-flex flex-column">
            <p>
            To proceed, you must select one of the following courses to unlock your dashboard and complete your profile. Choose wisely based on your interests and career goals!
            </p>

            <ul className="provider-verification ">
              {curriculumFeatures.map((_data) => {
                return (
                  <li key={_data}>
                    <h6>{_data?.title}</h6>
                    <p>{_data?.description}</p>
                  </li>
                );
              })}
            </ul>

           

            <div className="col-lg-12  w-100 d-flex justify-content-center">
              <div className="form-group mb-3">
                <button
                  type="button"
                  onClick={() => navigate(Path.FELLOW_PROFILE)}
                  className="submit btn btn-pigment-green"
                >
                  Go to Profile
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeeptechCoursesModal;

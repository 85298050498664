/** @format */


// export const extractFilterValues = (filter) => {
//   const res = {};
//   for (const key of Object.keys(filter)) {
//     if (key === "gender" || key === "page" ||  key === "cohort_id" || key="duration" ) {
//       res[key] = filter[key];
//     } else {
//       const values = filter[key].map((value) => value?.value);
//       res[key] = values;
//     }
//   }

//   return res;
// };

export const extractFilterValues = (filter) => {
  const res = {};
  for (const [key, value] of Object.entries(filter)) {
    if (["gender", "page", "cohort_id", "duration", "program", "min_score", "max_score", "category"].includes(key)) {
      res[key] = value;
    } else {
      res[key] = value.map((item) => item?.value);
    }
  }
  return res;
};

export const checkProfileCompletion = (profile) => {
  if (profile) {
    // Fields to check for completeness
    const requiredFields = [
      "address",
      "age",
      "gender",
      "phone",
      "residence",
      "lga",
      "state_of_origin",
      "current_learning_course",
    ];

    // Find incomplete fields
    const incompleteFields = requiredFields.filter((field) => !profile[field]);


    if (incompleteFields.length > 0) {
      return {
        message: `Your profile is incomplete. Missing fields: ${incompleteFields.join(
          ", "
        )}. Complete your profile to unlock full portal access.`,
        incompleteFields,
        showBanner:true
      };
    } else {
      return {
        message: "Your profile is complete. Enjoy full access to the portal.",
        incompleteFields: [],
        showBanner:false
      };
    }
  } else {
    return null;
  }
};

/** @format */

import React, { Fragment, useEffect, useState } from "react";
import { FadeLoader } from "react-spinners";
import ProviderLayout from "../../Provider";
import EmptyNotice from "component/ErrorNotice";
import FellowLeaderboardTable from "./components/tables/FellowLeaderBoardTable";
import LeaderStats from "./components/tables/LeaderStats";
import LeaderUserTracker from "./components/tables/LeaderUserTracker";
import { useGetLeaderProviderFellowsQuery } from "store/services/adminAPI";

const ALCLeaderBoardPage = () => {
  const [filterString, setFilterString] = useState("");
  const [fetch, setFetch] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);

  const {
    isLoading: loadingFellows,
    isFetching: fetchingFellows,
    data: leaderboardResult,
  } = useGetLeaderProviderFellowsQuery(filterString, {
    skip: fetch,
  });

  const leaderboardData = leaderboardResult?.data?.results ?? [];

  useEffect(() => {
    if (filterString && filterString?.length > 0) {
      setFetch(false);
    }
  }, [filterString]);



  return (
    <Fragment>
      <ProviderLayout>
        <div className="row w-100">
          <div className="col-12 col-xl-8">
            <LeaderStats />

            {loadingFellows || fetchingFellows ? (
              <div className="d-flex justify-content-center align-items-center height-15 bg-white">
                <FadeLoader color="#36d7b7" />
              </div>
            ) : (
              <>
                {leaderboardData?.length > 0 ? (
                  <FellowLeaderboardTable
                    leaderboardData={leaderboardData}
                    metadata={leaderboardResult?.data?.metadata}
                    isLoading={loadingFellows || fetchingFellows}
                    setFilterString={setFilterString}
                    setActiveRecord={setActiveRecord}
                  />
                ) : (
                  <div className="d-flex flex-column justify-content-center align-items-center height-15 bg-white">
                    <EmptyNotice text="No data available yet" />
                    {filterString?.length > 0 && (
                      <button
                        className="btn btn-primary"
                        onClick={() => setFilterString("")}
                      >
                        Reset Filter
                      </button>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="col-12 col-xl-4">
            <LeaderUserTracker activeRecord={activeRecord} />
          </div>
        </div>
      </ProviderLayout>
    </Fragment>
  );
};

export default ALCLeaderBoardPage;

/** @format */

import React, { Fragment, useState} from "react";
import { FadeLoader } from "react-spinners";
import FellowLayout from "../../Fellow";
import FellowPeerLearningTable from "./components/tables/FellowPeerLearningTable";
import WelcomeComponent from "./components/WelcomeComponent";
import GroupStatsComponent from "./components/GroupStatsComponent";
import { useGetFellowPeerLearningQuery } from "store/services/adminAPI";
import { useSelector } from "react-redux";
import PeerGuidleines from "./components/PeerGuidleines";
import LeaderUserTracker from "./components/LeaderUserTracker";
const FellowPeerLearningPage = () => {
  const [activeGuideline, setActiveGuideline] = React.useState(-1);
  const profileState = useSelector((state) => state.profile);
  const _profile = profileState?.profileInfo?.data?.profile || null;
  const [activeRecord, setActiveRecord] = useState(null);
  

  const {data, isLoading:loadingpeerLearning} = useGetFellowPeerLearningQuery();

  return (
    <Fragment>
      <FellowLayout>
        {loadingpeerLearning ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-12 col-xl-8">
                <WelcomeComponent setActiveGuideline={setActiveGuideline} name={_profile?.first_name} setActiveRecord={setActiveRecord}/>
                <GroupStatsComponent 
                  name={data?.data?.group?.name}
                  score = {data?.data?.group_total_score}
                />
                  <FellowPeerLearningTable 
                  leaderboardData={data?.data?.members}
                  setActiveRecord={setActiveRecord}
                  />
              </div>
              <div className="col-12 col-xl-4">
                {
                  activeRecord ? (
                    <LeaderUserTracker activeRecord={activeRecord}/>
                  ) : (
                    <PeerGuidleines setActiveGuideline={setActiveGuideline} activeGuideline={activeGuideline} />
                  )
                }
              </div>
            </div>
          </>
        )}
      </FellowLayout>
    </Fragment>
  );
};

export default FellowPeerLearningPage;

/** @format */

import React, { useRef, useState, useEffect } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useSelector } from "react-redux";
import { useGetCarouselBroadcastsQuery } from "store/services/notification";

const CarouselComponent = () => {
  const carouselRef = useRef(null);

  const profileState = useSelector((state) => state.profile);
  const _profile = profileState?.profileInfo?.data?.profile || null;
  const fellowType = _profile?.current_program ?? null;

  const [skip, setSkip] = useState(true);
  const [program, setProgram] = useState(null);
  const [filter, setFilter] = useState(
    `page=1${program ? `&program=${program}` : ""}`
  );

  const { data: carousels, isLoading } = useGetCarouselBroadcastsQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (fellowType) {
      setProgram(fellowType);
      const filterString = `page=1&program=${fellowType}`;
      setFilter(filterString);
      setSkip(false);
    }
  }, [fellowType]);

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "300px" }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const TruncatedText = ({ text, maxLines = 3 }) => {
    return (
      <p
        className="card-text lead text-center mx-auto"
        style={{
          maxWidth: `70%`,
          display: "-webkit-box",
          WebkitLineClamp: maxLines.toString(),
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
          wordBreak: "break-word",
        }}
      >
        {text}
      </p>
    );
  };

  if (carousels?.data?.length === 0) {
    return null;
  }

  return (
    <div
      ref={carouselRef}
      id="carouselAnnouncements"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      {carousels?.data?.length > 0 && (
        <div className="carousel-indicators">
          {carousels?.data?.map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselAnnouncements"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : "false"}
            ></button>
          ))}
        </div>
      )}
      <div className="carousel-inner">
        {carousels?.data?.map((carousel, index) => (
          <div
            key={carousel.id || index}
            className={`carousel-item ${index === 0 ? "active" : ""}`}
          >
            <div className="card text-center dlab-join-card text-white carousel-custom-min-height">
              <div className="my-auto">
                <h3 className="display-6 text-white">{carousel.title}</h3>
                <p className="mt-4 lead">
                  <TruncatedText text={carousel?.message} />
                </p>
                {carousel.link && (
                  <a href={carousel.link} target="blank">
                    <button className="btn w-min bg-white text-primary animate-pulse mt-4">
                      Click here
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {carousels?.data?.length > 1 && (
        <>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselAnnouncements"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselAnnouncements"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </>
      )}
    </div>
  );
};

export default CarouselComponent;

/** @format */
import React, { useState, useEffect, useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { FadeLoader } from "react-spinners";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { COLUMNS } from "./helpers/columns";

const FellowPeerLearningTable = ({
  leaderboardData,
  isLoading,
  setActiveRecord,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(leaderboardData);
  }, [isLoading, leaderboardData]);


  const columns = useMemo(
      () => COLUMNS(setActiveRecord),
      [setActiveRecord]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = useTable(
    {
      columns: columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <div className="leaderboard-card">
        <div className="leaderboard-card-body">
          <div className="leaderboard-header">
            
          </div>
          {isLoading ? (
            <div className="loading-container">
              <FadeLoader color="#36d7b7" />
            </div>
          ) : (
            <div>
              {leaderboardData?.length > 0 ? (
                <>
                  <div className="table-responsive">
    <table {...getTableProps()} className="leaderboard-table">
        <thead>
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className="leaderboard-header-cell"
                        >
                            {column.render("Header")}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
    {page.map((row) => {
        prepareRow(row);
        return (
            <tr 
                {...row.getRowProps()}
                className="leaderboard-row"
            >
                {row.cells.map((cell) => {
                    return (
                        <td 
                            {...cell.getCellProps()}
                            className="leaderboard-cell"
                        >
                            {cell.render("Cell")}
                        </td>
                    );
                })}
            </tr>
        );
    })}
</tbody>
    </table>
</div>
                </>
              ) : (
                <div className="empty-state">
                  <BagIcon />
                  <h4 className="empty-state-message">
                    No group leaderboard data available yet.
                  </h4>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FellowPeerLearningTable;
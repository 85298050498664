import React, { useRef, useState, useEffect, useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { FadeLoader } from "react-spinners";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { ReactComponent as SearchIcon } from "images/svg/search.svg";
import { ReactComponent as FilterIcon } from "images/svg/filter.svg";
import { ReactComponent as GridViewIcon } from "images/svg/grid.svg";
import { ReactComponent as ChartViewIcon } from "images/svg/chart.svg";
import { ReactComponent as CalendarIcon } from "images/svg/calendar-l.svg";
import { ReactComponent as ResetIcon } from "images/svg/reset.svg";
import { extractFilterValues } from "utils/functions";
import { COLUMNS } from "./helpers/columns";
import "../../styles/index.css"
// import CategoryDropdown from "../categoryfilter";
import FeatureUnavailableModal from "../FeatureUnavailable";
import LeaderboardFilterModal from "../LeaderBoardFilterModal";

const FellowLeaderboardTable = ({
  leaderboardData,
  metadata,
  auth_user_row,
  isLoading,
  setFilterString,
  setActiveRecord
}) => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [isCurrentUserFixed, setIsCurrentUserFixed] = useState(false);

  const [isDropdownVisible] = useState(false);
  const gridIconRef = useRef(null);

  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const filterIconRef = useRef(null);
  const chartIconRef = useRef(null);

  const [isUnavailableModalVisible, setIsUnavailableModalVisible] = useState(false)

  const currentUserId = auth_user_row?.id;

  useEffect(() => {
    if (filter) {
      const values = extractFilterValues(filter);
      const filterString = `page=1${
        values?.category ? `&category=${values?.category}` : ""
      }${
        values?.gender ? `&gender=${values?.gender}` : ""
      }${
        values?.state?.length > 0
          ? `&state=${values?.state?.join(",")}`
          : ""
      }${
        values?.lga?.length > 0
          ? `&lga=${values?.lga?.join(",")}`
          : ""
      }${
        values?.course?.length > 0
          ? `&course=${values?.course?.join(",")}`
          : ""
      }${
        values?.min_score ? `&min_score=${values?.min_score}` : ""
      }${
        values?.max_score ? `&max_score=${values?.max_score}` : ""
      }`;
      setFilterString(filterString);
    }
  }, [filter, setFilterString]);

  useEffect(() => {
    if (!isLoading && leaderboardData && leaderboardData.length > 0 && currentUserId) {
      const currentUserIndex = leaderboardData.findIndex(item => item.id === currentUserId);
      
      if (currentUserIndex !== -1) {
        let newData = [...leaderboardData];
        
        if (currentUserIndex !== 2) {
          const currentUser = newData.splice(currentUserIndex, 1)[0];
          
          newData.splice(2, 0, currentUser);
          
          setIsCurrentUserFixed(true);
        }
        
        setData(newData);
      } else {
        setData(leaderboardData);
        setIsCurrentUserFixed(false);
      }
    } else {
      setData(leaderboardData || []);
      setIsCurrentUserFixed(false);
    }
  }, [isLoading, leaderboardData, currentUserId]);

  const toggleUnavailableModal = () => { 
    setIsFilterModalVisible(false)
    setIsUnavailableModalVisible(!isUnavailableModalVisible);
  }

  const toggleFilterModal = () => {
    setIsFilterModalVisible(!isFilterModalVisible);
  };

  const getFilterModalPosition = () => {
    if (filterIconRef.current) {
      const rect = filterIconRef.current.getBoundingClientRect();
      return {
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      };
    }
    return { top: 0, left: 0 };
  };

  const columns = useMemo(
    () => COLUMNS(setActiveRecord),
    [setActiveRecord]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageCount,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = (e) => {
    setFilterString(`?page=&search=${searchTerm}`)
  }

  return (
    <div>
      <div className="leaderboard-card">
        <div className="leaderboard-card-body">
          <div className="leaderboard-header">
            <div className="filter-controls">
              <button 
                className={`filter-btn ${isDropdownVisible ? 'active' : ''}`} 
                onClick={toggleUnavailableModal}
                ref={gridIconRef}
              >
                <GridViewIcon />
              </button>
              <button
                className={`filter-btn ${isUnavailableModalVisible ? 'active' : ''}`} 
                onClick={toggleUnavailableModal}
                ref={chartIconRef}
              >
                <ChartViewIcon />
              </button>
              {/* <CategoryDropdown 
                isVisible={isDropdownVisible} 
                position={getDropdownPosition()} 
                setIsVisible={setIsDropdownVisible}
                setFilter={setFilter}
              /> */}
            </div>
            
            <div className="search-wrapper">
              <div className="search-icon">
                <SearchIcon />
              </div>
              <input
                type="text"
                className="search-input"
                placeholder="Search by name, Fellow ID, or course"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
            </div>
            
            <div className="filter-controls">
              <button 
                className={`filter-btn ${isFilterModalVisible ? 'active' : ''}`} 
                onClick={toggleFilterModal} 
                ref={filterIconRef}
              >
                <FilterIcon />
              </button>
              <button
                className={`filter-btn ${isUnavailableModalVisible ? 'active' : ''}`} 
                onClick={toggleUnavailableModal} 
                ref={filterIconRef}
              >
                <CalendarIcon 
                />
              </button>
            </div>
          </div>
          
          <LeaderboardFilterModal 
            isVisible={isFilterModalVisible} 
            setIsFilterModalVisible = {setIsFilterModalVisible}
            position={getFilterModalPosition()} 
            setFilter={setFilter}
            setFilterString={setFilterString}
            setActiveRecord = {setActiveRecord}
          />
          <FeatureUnavailableModal 
            isVisible={isUnavailableModalVisible} 
            setIsVisible = {setIsUnavailableModalVisible}
          />

          {isLoading ? (
            <div className="loading-container">
              <FadeLoader color="#36d7b7" />
            </div>
          ) : (
            <div>
              {data?.length > 0 ? (
                <>
                  <div className="table-responsive">
                    <table
                      {...getTableProps()}
                      className="leaderboard-table"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                className="leaderboard-header-cell"
                              >
                                {column.render("Header")}
                                {isCurrentUserFixed && pageIndex === 0 && (
                                  <span className="visually-hidden">
                                    (Current user position is fixed)
                                  </span>
                                )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          const isCurrentUser = row.original.id === currentUserId;
                          return (
                            <tr 
                              {...row.getRowProps()}
                              className={`leaderboard-row ${isCurrentUser ? 'current-user-row' : ''}`}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <td 
                                    {...cell.getCellProps()}
                                    className="leaderboard-cell"
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="pagination-container">
                      <button
                        className="pagination-btn"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        &laquo;
                      </button>
                      <button
                        className="pagination-btn"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        &lt;
                      </button>
                      
                      {[...Array(Math.min(pageCount, 10))].map((_, i) => (
                        <button
                          key={i}
                          className={`pagination-btn ${pageIndex === i ? 'pagination-btn-active' : ''}`}
                          onClick={() => gotoPage(i)}
                        >
                          {i + 1}
                        </button>
                      ))}
                      
                      {pageCount > 10 && (
                        <span className="pagination-ellipsis">...</span>
                      )}
                      
                      {pageCount > 10 && (
                        <button
                          className="pagination-btn"
                          onClick={() => gotoPage(pageCount - 1)}
                        >
                          {pageCount}
                        </button>
                      )}
                      
                      <button
                        className="pagination-btn"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      >
                        &gt;
                      </button>
                      <button
                        className="pagination-btn"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        &raquo;
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="empty-state">
                  <BagIcon />
                  <p className="empty-state-message">
                    No leaderboard data available yet.
                  </p>
                  <button className="reset-filter table-reset" onClick={()=>setFilterString("")}>
                    <ResetIcon />
                      Reset Filter
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FellowLeaderboardTable;
/** @format */
import React from "react";
import { ReactComponent as TrophyIcon } from "images/svg/trophy.svg";
import "../../styles/styles.scss";
import Educat from "images/pro.png";
import { ReactComponent as ProfileIcon } from "images/svg/profile.svg";



const PeerGroupTracker = ({ activeRecord }) => {
  const { position, name, avg_darey_score, course__title, state } = activeRecord || {};

  return (
    <div className="leader-user-tracker">
      {activeRecord ? (
        <>
          <div className="profile-header">
            <figure className="profile-image">
              <ProfileIcon />
            </figure>

            <h2 className="text-center">{name}</h2>
            <p className="text-center">{course__title}</p>
            <div className="profile-stats">
              <div className="svg-icon">
                <TrophyIcon />
              </div>
              <span className="profile-rank">{Math.round(avg_darey_score)*10}</span>
            </div>
          </div>


          <div className="alc-details">
            <h2>Peer group Details</h2>
            <h4>
              State: <span>{state}</span>
            </h4>
            <h4>
              Position: <span>{position}</span>
            </h4>
            <h4>
              Score: <span>{Math.round(avg_darey_score)*10}</span>
            </h4>
            <h4>
              Course: <span>{course__title}</span>
            </h4>
          </div>
        </>
      ) : (
        <div className="empty-profile">
        <figure>
          <img src={Educat} alt="Educat" />
        </figure>
          <h2>No Peer group Selected</h2>
          <p>Click on any peer group to see detailed view </p>
        </div>
      )}
    </div>
  );
};

export default PeerGroupTracker;

/** @format */

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import logo from "images/log.png";
import { Path } from "utils/constant.data";
import { login } from "store/reducers/AuthReducer";
import { useLoginUserMutation } from "store/services/authAPI";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import {
  extractErrorMessage,
  replaceUnderscoresWithSpaces,
} from "utils/strings";
import loginbg from "images/bg-1.svg";
import MMLogo from "images/3MTT/3MM.png";

function Login() {
  const dispatch = useDispatch();
  const [isShown, setIsSHown] = useState(false);
  const [loginUser, { isLoading: authenticating }] = useLoginUserMutation();

  const handleSubmit = async (values) => {
    await loginUser(values)
      .unwrap()
      .then((data) => {
        dispatch(login(data));
        // console.log("login", data);
      })
      .catch((error) => {
        const formattedError = extractErrorMessage(error);
        Swal.fire({
          title: error?.data?.code
            ? replaceUnderscoresWithSpaces(error?.data?.code)
            : "Error Occured",
          text: formattedError,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  return (
    <>
      <div
        className="login-main-page"
        style={{
          backgroundSize: "cover",
          background:
            "linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(" +
            loginbg +
            ")",
        }}
      >
        <div className="login-wrapper">
          <div className="container h-100">
            <div className="row h-100 align-items-center justify-contain-center">
              <div className="col-xl-12 mt-3">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="row m-0">
                      <div className="col-xl-6 col-md-6 sign text-center">
                        <div>
                          <div className="text-center mm__logo my-4 ">
                            <Link to={Path.LOGIN}>
                              <img src={MMLogo} alt="" />
                            </Link>
                          </div>
                          {/* <figure className="peoeple-login">
                            <img
                              src={People}
                              alt="text"
                              className="education-img"
                            ></img>
                          </figure> */}
                          <img
                            src={logo}
                            alt="text"
                            className="education-img"
                          ></img>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6">
                        <div className="sign-in-your">
                          <h4 className="fs-20 font-w800 text-black">
                            Sign in
                          </h4>

                          <p className="mb-2">
                            Welcome back! Please log in using the details you
                            entered during registration.
                          </p>

                          <form className="form" onSubmit={formik.handleSubmit}>
                            <div className="mb-3">
                              <label className="mb-1">
                                <strong>Email</strong>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder="e.g. example@domainname.com"
                                {...formik.getFieldProps("email")}
                              />
                              {formik.touched.email && formik.errors.email ? (
                                <div className="text-danger mt-2 fs-12">
                                  {formik.errors.email}
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group mb-3">
                              <label>
                                {" "}
                                <strong>Password</strong>
                              </label>

                              <div className="password__container">
                                <input
                                  type={isShown ? "text" : "password"}
                                  id="password"
                                  name="password"
                                  placeholder="Enter Password"
                                  {...formik.getFieldProps("password")}
                                ></input>
                                <button type="button" onClick={togglePassword}>
                                  <div class="icon">
                                    {isShown ? (
                                      <i class="bi bi-eye"></i>
                                    ) : (
                                      <i class="bi bi-eye-slash"></i>
                                    )}
                                  </div>
                                </button>
                              </div>

                              {formik.touched.password &&
                              formik.errors.password ? (
                                <div className="text-danger mt-2 fs-12">
                                  {formik.errors.password}
                                </div>
                              ) : null}
                            </div>

                            <div className="row d-flex justify-content-between mt-2 mb-2">
                              <div className="mb-3 basis-half">
                                <div className="form-check custom-checkbox ms-1">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="basic_checkbox_1"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="basic_checkbox_1"
                                  >
                                    Remember Me
                                  </label>
                                </div>
                              </div>
                              <div className="mb-3 basis-half d-flex justify-content-end align-items-center">
                                <p className="">
                                  <Link
                                    className="text-primary"
                                    to={Path.FORGOT_PASSWORD}
                                  >
                                    Forgot Password
                                  </Link>
                                </p>
                              </div>
                            </div>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                              >
                                {authenticating
                                  ? "Authenticating....."
                                  : "Login"}
                              </button>
                            </div>
                            <div className="new-account mt-2">
                              <p className="m-0">
                                Don't have an account?{" "}
                                <Link className="text-primary" to={Path.APPLY}>
                                  Register here
                                </Link>
                              </p>
                            </div>
                            <div className="new-account">
                              <p className="">
                                Don't have your password?{" "}
                                <Link
                                  className="text-primary"
                                  to={Path.REGISTER}
                                >
                                  Complete Registration
                                </Link>
                              </p>
                            </div>
                            <div className="new-account mt-2 text-center">
                              <p className="">
                                <Link
                                  className="text-primary"
                                  to={Path.SELECTED_FELLOWS}
                                >
                                  See all selected fellows
                                </Link>
                              </p>
                              <p className="">
                                <Link
                                  className="text-primary"
                                  to={Path.FELLOW_LEADER_BOARD_PUBLIC}
                                >
                                  View Leaderboard
                                </Link>
                              </p>
                            </div>
                            

                            <div className="new-account mt-2 text-center">
                              <p className="">
                                <Link
                                  className="text-primary"
                                  to={Path.PUBLIC_JOBS}
                                >
                                  View 3MTT Jobs
                                </Link>
                              </p>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(4, "Password is too short - should be 4 chars minimum"),
});



import React from "react";
import { ReactComponent as PeerGroupIcon } from "images/svg/peer-group-name.svg";
import { ReactComponent as PeerGroupScore } from "images/svg/peer-group-score.svg";
import { ReactComponent as BgIcon } from "images/svg/bg.svg";
import { ReactComponent as BgBottomIcon } from "images/svg/bg-bottom.svg";




const GroupStatsComponent = ({name, score}) => {

  return (
    <div className="group-stat-cards">
      <div className="peer-group-card">
        <PeerGroupIcon />
        <h3 className="stat-title">Peer Group Name</h3>
        <h3 className="stat-count">{name || "N/A"}</h3>
        <div className="leader-bg">
          <BgIcon />
        </div>
      </div>

      <div className="peer-group-card">
        <PeerGroupScore />
        <h3 className="stat-title">Peer Group Average Darey Score</h3>
        <h3 className="stat-count">{score ? Math.round(score)*10 : "N/A"}</h3>
        <div className="leader-bg-bottom">
          <BgBottomIcon />
        </div>
      </div>
    </div>
  );
};

export default GroupStatsComponent;

